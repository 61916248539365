import { Button, SelectInput, Title } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";

const QuestionItemEditTitle = ({
  explanations,
  question,
  index,
  handleChangeQuestion,
  allScripts,
  handleRemoveQuestion,
  allTOpics = [],
}) => {
  const renderActions = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "baseline",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <SelectInput
            options={allScripts}
            style={{ width: 180 }}
            placeholder="Select Scripts"
            getOptionValue={(e) => e?._id}
            getOptionLabel={(e) => e?.page_name}
            value={question?.scripts}
            mode="multiple"
            onChange={(value) => {
              handleChangeQuestion({ ...question, scripts: value }, index);
            }}
            getPopupContainer={false}
          />
          <SelectInput
            options={explanations}
            style={{ width: 180 }}
            placeholder="Select Explanation"
            getOptionValue={(e) => e?.title}
            getOptionLabel={(e) => e?.title}
            value={question?.explanation}
            mode="multiple"
            onChange={(value) => {
              handleChangeQuestion({ ...question, explanation: value }, index);
            }}
            getPopupContainer={false}
          />
          <SelectInput
            placeholder="Add Topics"
            options={
              allTOpics?.data?.length
                ? allTOpics?.data?.map((e) => e?.PageTopics)
                : []
            }
            style={{ width: 180 }}
            getOptionValue={(e) => e}
            getOptionLabel={(e) => e}
            onChange={(value) => {
              handleChangeQuestion({ ...question, topics: value }, index);
            }}
            value={question?.topics}
            mode="tags"
          />
          <Button
            type="link"
            danger
            style={{
              height: 25,
              textDecoration: "underline",
            }}
            onClick={() => {
              handleRemoveQuestion(index);
            }}
            text="Delete Question"
          >
            Delete Question
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={4}>{`${index + 1}. Question`}</Title>
        {renderActions()}
      </div>
      <TextEditor
        key={`${index}-question`}
        placeholder={"Add question here"}
        value={question?.description}
        onChange={(value) => {
          handleChangeQuestion({ ...question, description: value }, index);
        }}
        editorHeight={150}
      />
    </div>
  );
};

export default QuestionItemEditTitle;
