import { Link } from "react-router-dom";
import {
  ACCOUNTS_PATH,
  APPROVED_PAGES,
  APP_USERS,
  CONTACT_US,
  CREATE_GRAPH,
  CREATE_TEST_FORM,
  DASHBOARD,
  EDITED_PAGES,
  FAVORITE_PAGES,
  HIDDEN_PAGES,
  PRESENTATION_PATH,
  SAVED,
  SCRIPT,
  SCRIPT_FEEDBACK_PATH,
  SCRIPT_QUESTIONS_LISTING,
  SCRIPT_QUIZ_LISTING,
  SCRIPT_TEMPLATE_SETTINGS_PATH,
} from "../app-paths";
import { menuItemIcons } from "./menuItems-icons";

const getLink = (label, path, isSelected = false) => {
  return (
    <Link style={{ color: isSelected ? "white" : "black" }} to={path}>
      {label}
    </Link>
  );
};

export const menuItems = (handleLogoClick, isMobile, selectedKeys) => {
  console.log("===sele", selectedKeys);
  const regularItems = [
    {
      label: (
        <>
          <span style={{ position: "relative", top: "0.7vh" }}>
            {menuItemIcons.DASHBOARD_ICON}
          </span>{" "}
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {getLink("Dashboard", DASHBOARD, false)}
          </span>
        </>
      ),
      key: "dashboard",
    },
    {
      label: (
        <>
          <span style={{ position: "relative", top: "0.7vh" }}>
            {menuItemIcons.STUDY_SESSION}
          </span>
          <span style={{ position: "relative", marginLeft: "0.5vw" }}>
            {getLink("Tests")}
          </span>
        </>
      ),
      key: "tests",
      children: [
        {
          label: (
            <>
              {getLink(
                "Create Test",
                CREATE_TEST_FORM,
                selectedKeys === "create-test"
              )}
            </>
          ),
          key: "create-test",
        },
        {
          label: (
            <>
              {getLink(
                "Quizzes",
                SCRIPT_QUIZ_LISTING,
                selectedKeys === "quiz-library"
              )}
            </>
          ),
          key: "quiz-library",
        },
        // {
        //   label: (
        //     <>
        //       {getLink(
        //         "Questions",
        //         SCRIPT_QUESTIONS_LISTING,
        //         selectedKeys === "questions"
        //       )}
        //     </>
        //   ),
        //   key: "questions",
        // },
      ],
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            // dangerouslySetInnerHTML={{ __html: menuItemIcons.STUDY_ICON }}
          >
            {menuItemIcons.STUDY_ICON}
          </span>
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {" "}
            {getLink(
              "Presentation",
              PRESENTATION_PATH,
              selectedKeys === "presentation"
            )}{" "}
          </span>
        </>
      ),
      key: "presentation",
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            dangerouslySetInnerHTML={{ __html: menuItemIcons.SCRIPT_ICON }}
          />{" "}
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {getLink("Scripts", SCRIPT, false)}
          </span>
        </>
      ),
      key: "script",
      children: [
        {
          label: (
            <>
              {getLink(
                "My Favorites",
                FAVORITE_PAGES,
                selectedKeys === "favorite-scripts"
              )}
            </>
          ),
          key: "favorite-scripts",
        },
        {
          label: (
            <>
              {getLink(
                "Edited Scripts",
                EDITED_PAGES,
                selectedKeys === "editedpages"
              )}
            </>
          ),
          key: "editedpages",
        },
        {
          label: (
            <>
              {getLink(
                "Hidden Scripts",
                HIDDEN_PAGES,
                selectedKeys === "hiddenpages"
              )}
            </>
          ),
          key: "hiddenpages",
        },
      ],
    },
    // {
    //   label: (
    //     <>
    //       <span style={{ position: "relative", top: "0.7vh" }}>
    //         {menuItemIcons.SCRIPT_QUIZ}
    //       </span>{" "}
    //       <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
    //         {getLink(
    //           "Quiz",
    //           SCRIPT_QUIZ_LISTING,
    //           selectedKeys === "quiz-library"
    //         )}
    //       </span>
    //     </>
    //   ),
    //   key: "quiz-library",
    // },
    {
      label: (
        <>
          <span style={{ position: "relative", top: "0.7vh" }}>
            {menuItemIcons.SCRIPT_QUIZ}
          </span>{" "}
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {getLink(
              "Questions",
              SCRIPT_QUESTIONS_LISTING,
              selectedKeys === "script-questions"
            )}
          </span>
        </>
      ),
      key: "script-questions",
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            // dangerouslySetInnerHTML={{
            //   __html: menuItemIcons.TEMPLATE_SETTINGS_ICON,
            // }}
          >
            {menuItemIcons.TEMPLATE_SETTINGS_ICON}
          </span>
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {getLink(
              "Template Settings",
              SCRIPT_TEMPLATE_SETTINGS_PATH,
              selectedKeys === "template-settings"
            )}
          </span>
        </>
      ),
      key: "template-settings",
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            // dangerouslySetInnerHTML={{
            //   __html: menuItemIcons.TEMPLATE_SETTINGS_ICON,
            // }}
          >
            {menuItemIcons.SCRIPT_FEEDBACK}
          </span>
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {getLink(
              "Feedback",
              SCRIPT_FEEDBACK_PATH,
              selectedKeys === "script-feedback"
            )}
          </span>
        </>
      ),
      key: "script-feedback",
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            dangerouslySetInnerHTML={{ __html: menuItemIcons.COMPARE_ICON }}
          />{" "}
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {getLink("Compare")}
          </span>
        </>
      ),
      key: "compare",
      children: [
        {
          label: <>{getLink("Saved", SAVED, selectedKeys === "saved")}</>,
          key: "saved",
        },
      ],
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            dangerouslySetInnerHTML={{ __html: menuItemIcons.GRAPH_ICON }}
          />{" "}
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {" "}
            {getLink(
              "Create Graph",
              CREATE_GRAPH,
              selectedKeys === "creategraph"
            )}{" "}
          </span>
        </>
      ),
      key: "creategraph",
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            // dangerouslySetInnerHTML={{ __html: menuItemIcons.PAGES_ICON }}
          >
            {menuItemIcons.PAGES_ICON}
          </span>
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {" "}
            {getLink(
              "Approved Pages",
              APPROVED_PAGES,
              selectedKeys === "approvedpages"
            )}{" "}
          </span>
        </>
      ),
      key: "approvedpages",
    },
    {
      label: (
        <>
          <span style={{ position: "relative", top: "0.7vh" }}>
            {menuItemIcons.USERS_ICON}
          </span>{" "}
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {" "}
            {getLink("Users", APP_USERS, selectedKeys === "app-users")}{" "}
          </span>
        </>
      ),
      key: "app-users",
    },
    {
      label: (
        <>
          <span style={{ position: "relative", top: "0.7vh" }}>
            {menuItemIcons.ACCOUNTS_ICON}
          </span>{" "}
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {" "}
            {getLink("Account", ACCOUNTS_PATH, false)}
          </span>
        </>
      ),
      key: "account",
    },
    {
      label: (
        <>
          <span
            style={{ position: "relative", top: "0.5vh" }}
            // dangerouslySetInnerHTML={{ __html: menuItemIcons.CONTACT_ICON }}
          >
            {menuItemIcons.CONTACT_ICON}
          </span>
          <span style={{ position: "absolute", marginLeft: "0.5vw" }}>
            {" "}
            {getLink(
              "Contact Us",
              CONTACT_US,
              selectedKeys === "contactus"
            )}{" "}
          </span>
        </>
      ),
      key: "contactus",
    },
  ];
  //in mobile mode we have to add an aditional item of logo because in design there is a logo
  //inside the sidenav
  const mobileItemLogo = [
    {
      label: (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: menuItemIcons.MOBILE_SIDENAV_LOGO,
            }}
          />{" "}
        </>
      ),
      key: "mobileSidenavLogo",
      onClick: handleLogoClick,
      title: null,
    },
  ];

  return isMobile ? [...mobileItemLogo, ...regularItems] : regularItems;
};
