import {
  Button,
  Card,
  DataTable,
  Group,
  Icon,
  Tag,
  Title,
  Tooltip,
} from "components/common";
import {
  SCRIPT_ADD_QUESTIONS,
  SCRIPT_EDIT_QUESTIONS,
  SCRIPT_VIEW_QUESTIONS,
} from "constants/app-paths";
import {
  useGetPageTopicsQuery,
  useGetScriptDetailQuery,
  useGetSearchListingScriptTopicsQuery,
  useGetSpecialitiesQuery,
} from "features/script/api";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import theme from "utils/themeVars";
import { useGetAllQuestionsQuery } from "../apis";
import { ActionButtons, Filters, TagsWithPlus } from "components/custom";
import { canUserModify } from "features/presentation/utils";
import { useSelector } from "react-redux";
import { SCRIPT_EDIT_ICON } from "components/svg";

const multipleModes = {
  specialties: true,
  scripts: true,
  topics: true,
  specialty: true,
  level: true,
};

function processObject(inputObj) {
  // Filter out keys with empty or undefined values
  const filteredObj = Object.fromEntries(
    Object.entries(inputObj).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  // Convert comma-separated values to arrays
  const processedObj = Object.fromEntries(
    Object.entries(filteredObj).map(([key, value]) => {
      return [key, multipleModes?.[key] ? value.split(",") : value];
    })
  );

  return processedObj;
}

const QuestionsListing = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);

  const user = useSelector((state) => state.app.user?.info);

  const getUserId = (queryObj) => {
    const keysToCheck = ["upVoted", "downVoted", "bookmarked"];
    if (keysToCheck.some((key) => queryObj.hasOwnProperty(key))) {
      return { userId: user?.user_code };
    }
  };

  const {
    data: allQuestions,
    isLoading: isLoadingQuestions,
    isFetching: isFetchingQuestions,
    error: questionsError,
    refetch: refetchQuestions,
  } = useGetAllQuestionsQuery(
    {
      ...processObject(queryObj),
      ...(queryObj?.page && { pageNum: queryObj?.page }),
      ...(queryObj?.per_page && { pageSize: queryObj?.per_page }),
      ...getUserId(queryObj),
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: allScripts,
    isLoading: isLoadingScripts,
    isFetching: isFetchingScripts,
    error: scriptsError,
    refetch: refetchScripts,
  } = useGetPageTopicsQuery("");

  const { data: allTopics } = useGetSearchListingScriptTopicsQuery();
  const { data: specialties } = useGetSpecialitiesQuery();

  const findScript = (scripts) => {
    if (allScripts?.length && scripts?.length) {
      return allScripts
        .filter(
          (e) =>
            scripts.includes(e.editor_template_id) || scripts?.includes(e?._id)
        )
        ?.map((e) => e?.page_name);
    }
    return ["-"];
  };

  const findTopic = (topics) => {
    if (allTopics?.data?.length && topics?.length) {
      return allTopics?.data
        .filter((topic) => topics.includes(topic._id))
        ?.map((e) => e?.PageTopics);
    }
    return ["-"];
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "key",
      className: "tableStartSpace",
      width: 100,
    },
    // {
    //   title: "Template Type",
    //   dataIndex: "templateType",
    // },
    // {
    //   title: "Specialty",
    //   dataIndex: "specialty",
    // },
    {
      title: "Scripts",
      dataIndex: "script",
    },
    {
      title: "explanation",
      dataIndex: "explanation",
    },
    {
      title: "Topics",
      dataIndex: "topics",
    },
    {
      title: "Question ID",
      dataIndex: "question_id",
    },
    {
      title: "Actions",
      dataIndex: "actions",
    },
  ];

  const getActionsList = (e) => [
    {
      key: "view_questions",
      icon: <Icon icon="IoMdEye" />,
      color: canUserModify(user, e?.editor_id)
        ? theme["blue200"]
        : theme["gray500"],
      content: "View Question",
      title: "View Question",
      className: "pointer",
      onClick: () => {
        navigate(SCRIPT_VIEW_QUESTIONS.replace(":id", e?.questionId));
      },
    },
    {
      key: "edit_question",
      icon: <SCRIPT_EDIT_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["blue200"]
        : theme["gray500"],
      content: "Edit Question",
      title: "Edit Question",
      className: "pointer",
      onClick: () => {
        navigate(SCRIPT_EDIT_QUESTIONS.replace(":id", e?.questionId));
      },
    },
  ];

  const tableData = useCallback(() => {
    return allQuestions?.data?.length
      ? allQuestions?.data?.map((e, index) => ({
          ...e,
          key: index + 1,
          script: <TagsWithPlus data={findScript(e?.scripts)} plusAfter={3} />,
          explanation: (
            <TagsWithPlus
              data={e?.explanation?.map((e) => e?.title)}
              plusAfter={3}
            />
          ),
          topics: <TagsWithPlus data={findTopic(e?.topics)} plusAfter={3} />,
          question_id: e?.questionId,
          actions: <ActionButtons list={getActionsList(e)} />,
        }))
      : [];
  }, [allQuestions, allScripts, allTopics]);

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>Questions Library</Title>
        <Button
          text={"Add Question"}
          icon={"HiPlus"}
          type="bordered"
          style={{
            borderColor: theme["blue100"],
            color: theme["blue200"],
            background: theme["light-gray"],
          }}
          onClick={() => {
            navigate(SCRIPT_ADD_QUESTIONS);
          }}
          size="small"
        />
      </div>
    );
  };

  const filterList = useMemo(
    () => [
      {
        label: "none",
        type: "input",
        key: "questionIid",
        placeholder: "Search by Question ID",
      },
      {
        label: "none",
        type: "input",
        key: "searchedString",
        placeholder: "Search by Keyword",
      },
      {
        label: "none",
        list: specialties?.data.map((plat) => ({
          label: plat.topic,
          value: plat.topic,
        })),
        type: "select",
        key: "specialty",
        placeholder: "Select Specialty",
      },
      {
        label: "none",
        list: allScripts?.length
          ? allScripts.map((e) => ({
              label: e?.page_name,
              value: e?.editor_template_id,
            }))
          : [],
        type: "select",
        key: "scripts",
        placeholder: "Select Script",
        mode: "multiple",
      },
      {
        label: "none",
        list: allTopics?.data?.length
          ? allTopics?.data?.map((e) => ({
              ...e,
              label: e?.PageTopics,
              value: e?._id,
            }))
          : [],
        type: "select",
        key: "topics",
        placeholder: "Select Topics",
      },
      {
        label: "none",
        list: [
          { label: "Easy", value: "easy" },
          { label: "Medium", value: "medium" },
          { label: "Hard", value: "Hard" },
        ],
        type: "select",
        key: "level",
        placeholder: "Select Difficulty",
        mode: "multiple",
      },
      // {
      //   label: "none",
      //   list: [],
      //   type: "select",
      //   key: "users",
      //   placeholder: "Select Users",
      // },
      {
        key: "bookmarked",
        label: "Bookmarked",
        type: "toggle",
      },
      {
        key: "upVoted",
        label: "UpVoted",
        type: "toggle",
      },
      {
        key: "downVoted",
        label: "DownVoted",
        type: "toggle",
      },
    ],
    [allScripts, allTopics?.data, specialties?.data]
  );

  return (
    <Group gap="16px">
      <Card>
        {renderPageHeader()}
        <Filters list={filterList || []} />
      </Card>
      <DataTable
        pagination={{
          totalCount: allQuestions?.totalCount,
          pageOptions: [10, 25, 50],
        }}
        paginationOnBottom={true}
        onChangePage={(queryObj) => setSearchParams(queryObj)}
        columns={columns}
        data={tableData()}
        loading={isLoadingQuestions || isLoadingScripts}
        error={questionsError || scriptsError}
        onErrorRetry={() => {
          if (questionsError) refetchQuestions();
          if (scriptsError) refetchScripts();
        }}
        skeletonLoading={isFetchingQuestions || isFetchingScripts}
      />
    </Group>
  );
};

export default QuestionsListing;
