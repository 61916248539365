import {
  useGetAllQuestionsOfScriptQuery,
  useGetScriptDetailQuery,
  useGetScriptTypeDetailsQuery,
} from "features/script/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
  setScriptsData,
  setTemplateDetails,
  setScriptPodCastData,
} from "store/scriptSlice";
import styles from "../index.module.less";
import ScriptLeftPanel from "./scriptLeftPanel";
import ScriptRightPanel from "./scriptRightPanel";

const initialValuesForTemplate = {
  name: "",
  templateId: "",
  templateName: "",
  primary_specialty: null,
  secondary_specialty: [],
  topics: [],
  versions: [],
  presentationSection: {
    titleDescriptions: [
      { key: "1", placeholder: "Enter Title and Description" },
      { key: "2", placeholder: "Enter Title and Description" },
      { key: "3", placeholder: "Enter Title and Description" },
    ],
    media: [],
  },
  explanations: [],
  podCast: [],
  questions: [],
};

const AddScriptForm = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);

  let scriptData = useSelector((state) => state?.scripts?.data);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const userInfo = useSelector((state) => state.app.user.info);

  const [podCastData, setPodCastData] = useState([]);

  const handlePodCastData = (data) => {
    setPodCastData(data);
  };

  useEffect(() => {
    if (scriptData) {
      dispatch(setScriptPodCastData(podCastData));
    }
  }, [podCastData]);

  const { data: scriptDetails, isFetching: scriptLoading } =
    useGetScriptDetailQuery(
      {
        editor_template_id: queryObj?.editorId,
        version: queryObj?.version,
      },
      { skip: !(queryObj?.editorId || queryObj?.version) }
    );

  const {
    data: templateDetails,
    isFetching: templateLoading,
    isLoading,
    error,
    refetch,
  } = useGetScriptTypeDetailsQuery(
    { base_template_id: Number(queryObj?.template_id) },
    { skip: !queryObj?.template_id }
  );

  useEffect(() => {
    setInitialData();
    if (templateDetails?.data) {
      setTemplateData(templateDetails?.data);
    } else if (scriptDetails) {
      setTemplateData(scriptDetails);
    }
  }, [templateDetails, scriptDetails]);

  const getAllMedia = (e) => {
    let arr = [];
    let temp = [];
    let temp2 = [];
    if (e?.media?.length) {
      arr = e?.media.map((it) => ({
        ...e,
        type: "image",
        imageDescription: it?.imageDescription,
        imageTitle: it?.imageTitle,
        url: it?.url,
        imageUrl: it?.imageUrl,
      }));
    }
    if (e?.LicensedImage?.length) {
      temp = [];
      temp = e?.LicensedImage?.map((it) => ({
        ...it,
        type: "licenseImage",
        ImageURL: it?.ImageURL,
        siteURL: it?.siteURL,
        imageTitle: it?.imageTitle,
      }));
    }
    if (e?.LicensedPodcast?.length) {
      temp = [];
      temp = e?.LicensedPodcast?.map((it) => ({
        ...it,
        type: "licensePodcast",
        PodcastURL: it?.PodcastURL,
        siteURL: it?.siteURL,
        podcastTitle: it?.podcastTitle,
      }));
    }
    if (e?.YTIframe?.length) {
      temp2 = e?.YTIframe?.map((it) => ({
        ...e,
        type: "licenseVideo",
        ImageURL: it?.VideoIframe,
        siteURL: it?.siteURL,
        imageTitle: it?.videoTitle,
      }));
    }
    return [...arr, ...temp, ...temp2];
  };

  const setTemplateData = (data) => {
    dispatch(
      setTemplateDetails({
        scriptId: data?.editor_template_id,
        editor_template_id: data?.editor_template_id,
        pageId: data?.page_id,
        name: data?.page_name,
        templateId: data?.base_template_id,
        primary_specialty: data?.primarySpeciality,
        secondary_specialty: data?.speciality,
        topics: data?.topics,
        templateName: data?.name,
        templateType: data?.type,
        presentationSection: {
          titleDescriptions: data?.section_1?.length
            ? data?.section_1.map((e, i) => ({
                key: i?.toString(),
                placeholder: "Add Description",
                title: e?.title,
                description: e?.description,
              }))
            : [
                {
                  key: "1",
                  placeholder: "Add Description",
                  title: "",
                  description: "",
                },
              ],
          media: data?.media?.length
            ? data?.media?.map((e) => ({ ...e, type: "image" }))
            : [],
        },
        explanations: data?.section_2?.length
          ? data?.section_2.map((e, i) => ({
              ...e,
              key: i?.toString(),
              title: e?.title,
              description: e?.explanation,
              media: getAllMedia(e),
            }))
          : [],
        schemas: data?.schemas?.length
          ? data?.schemas?.map((e) => ({
              title: e?.title,
              data: JSON.parse(e?.json),
            }))
          : [],
        assessmentAndPlans: data?.assessment_and_plans?.length
          ? data?.assessment_and_plans
          : [],
        podCast: data?.podCast?.length ? data?.podCast : [],
      })
    );
  };

  const setInitialData = () => {
    dispatch(setScriptsData({ ...initialValuesForTemplate }));
  };

  const getExpalanations = (exp, assessments, podcasts) => {
    if (!exp) {
      return [];
    }
    const temp = JSON.parse(JSON.stringify(exp));
    if (temp?.length) {
      temp.unshift({ title: "Presentation" });
      assessments?.length && temp.push({ title: "Assessment and Plan" });
      podcasts?.length && temp.push({ title: "Podcasts" });
      temp?.schemas?.length && temp.push({ title: "Schemas" });
    }
    return temp;
  };

  return (
    <div className={styles.pageContainer}>
      {!isMobile && (
        <ScriptLeftPanel
          skeletonLoading={templateLoading || scriptLoading}
          explanations={getExpalanations(
            scriptData?.explanations,
            scriptData?.assessmentAndPlans,
            scriptData?.podCast
          )}
        />
      )}
      <ScriptRightPanel
        isMobile={isMobile}
        data={scriptData}
        editMode={!queryObj?.template_id}
        initialData={scriptDetails}
        editorId={queryObj?.editorId}
        version={queryObj?.version}
        podCastDetails={handlePodCastData}
      />
    </div>
  );
};

export default AddScriptForm;
