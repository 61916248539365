import { Layout, Menu } from "antd";
import { Button } from "components/common";
import { UserMenu } from "components/custom";
import { LogoIllnessRx } from "components/svg";
import { HamBurger } from "components/svg";
import { NewLogoIllnessRx } from "components/svg";
import { MobileSideNavLogo } from "components/svg";
import { menuItems } from "constants/menuItems/menu-items";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./index.module.less";
const AppLayout = ({ children }) => {
  // using these svgs for now as these features havn't been implemented yet
  const mailSvg = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.125 12.2876L11.3444 17.857C12.0442 18.3311 12.9558 18.3311 13.6556 17.857L21.875 12.2876M5.20833 23.9337H19.7917C20.9423 23.9337 21.875 22.9857 21.875 21.8162V11.2288C21.875 10.0594 20.9423 9.11133 19.7917 9.11133H5.20833C4.05774 9.11133 3.125 10.0594 3.125 11.2288V21.8162C3.125 22.9857 4.05774 23.9337 5.20833 23.9337Z" stroke="#3F3F46" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <ellipse cx="21.5" cy="10.4245" rx="8.5" ry="8.63934" fill="#1565DC"/>
  <path d="M18.7243 13.7695V12.6365L20.9463 10.3155C21.3863 9.85353 21.7236 9.48686 21.9583 9.21553C22.2003 8.93686 22.3689 8.69853 22.4643 8.50053C22.5596 8.30253 22.6073 8.10086 22.6073 7.89553C22.6073 7.55086 22.4973 7.27953 22.2773 7.08153C22.0646 6.88353 21.7896 6.78453 21.4523 6.78453C21.1003 6.78453 20.7959 6.8872 20.5393 7.09253C20.2826 7.29053 20.0956 7.58386 19.9783 7.97253L18.6693 7.56553C18.7573 7.13286 18.9369 6.75886 19.2083 6.44353C19.4796 6.12086 19.8096 5.8752 20.1983 5.70653C20.5943 5.53053 21.0123 5.44253 21.4523 5.44253C21.9803 5.44253 22.4423 5.53786 22.8383 5.72853C23.2416 5.9192 23.5533 6.18686 23.7733 6.53153C24.0006 6.8762 24.1143 7.27953 24.1143 7.74153C24.1143 8.03486 24.0593 8.32453 23.9493 8.61053C23.8393 8.89653 23.6743 9.18986 23.4543 9.49053C23.2343 9.78386 22.9483 10.1139 22.5963 10.4805L20.6713 12.5045H24.2243V13.7695H18.7243Z" fill="white"/>
  </svg>
  `;

  const bellSvg = `<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.6253 18.2931H20.8337L19.3702 16.8056C18.9733 16.4023 18.7503 15.8551 18.7503 15.2846V11.9407C18.7503 9.17475 17.0111 6.82172 14.5837 5.94966V5.58819C14.5837 4.41873 13.6509 3.4707 12.5003 3.4707C11.3497 3.4707 10.417 4.41873 10.417 5.58819V5.94966C7.98951 6.82172 6.25033 9.17475 6.25033 11.9407V15.2846C6.25033 15.8551 6.02735 16.4023 5.63046 16.8056L4.16699 18.2931H9.37533M15.6253 18.2931V19.3518C15.6253 21.106 14.2262 22.5281 12.5003 22.5281C10.7744 22.5281 9.37533 21.106 9.37533 19.3518V18.2931M15.6253 18.2931H9.37533" stroke="#3F3F46" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`;
  const [activeItem, setActiveItem] = useState("presentation");
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState();
  // checking is the page is opened on mobile
  const [isMobile, setIsMobile] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const isModalOpen = useSelector((state) => state.modalReducer.isModalOpen);

  const handleMouseEnter = () => {
    setCollapsed(false);
  };

  const handleMouseLeave = () => {
    setCollapsed(true);
  };

  // on clicking logo from sideNav collapse side navbar in mobile view
  const handleLogoClick = () => {
    setCollapsed(true);
  };
  const menuList = useMemo(
    () => menuItems(handleLogoClick, isMobile, selectedItem),
    [handleLogoClick, selectedItem, isMobile]
  );

  useEffect(() => {
    menuItems().forEach((e) => {
      const selectedChild = e.children?.find((child) =>
        window.location.pathname.includes(child.key)
      );

      if (selectedChild) {
        setSelectedItem(selectedChild.key);
      } else if (window.location.pathname.includes(e.key)) {
        setSelectedItem(e.key);
      }
    });

    //  check if the screen size is exactly 360x800

    setIsMobile(window.innerWidth <= 700);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    // add eventListener for window resize

    window.addEventListener("resize", handleResize);

    // initial check on component mount
    handleResize();

    // remove the event listener when the component unmounts

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.location.pathname]);
  return (
    <>
      <div className={styles.navBar}>
        {!isMobile && (
          <div className={styles.header}>
            {!collapsed && (
              <div className={styles.headerLogo}>
                <NewLogoIllnessRx width={350} height="auto" />
              </div>
            )}

            {
              <div
                style={{
                  marginLeft: collapsed ? "1.6vw" : "0.5vw",
                }}
                className={styles.headerButtons}
              >
                <Button
                  style={{
                    background: "white",
                  }}
                  icon={<HamBurger />}
                  onClick={() => setCollapsed(!collapsed)}
                />
              </div>
            }
          </div>
        )}

        <Header
          style={{
            background: "#fff",
            border: "none",
          }}
          className={isMobile ? `${styles.mobileHeader}` : ""}
        >
          {isMobile && (
            <span
              className={styles.mobileSiderLogo}
              onClick={() => setCollapsed(!collapsed)}
            >
              <MobileSideNavLogo />
            </span>
          )}
          <div className={styles.headerDiv}>
            {/* reduced the opacity as these features haven't been implemented yet */}
            <>
              <span
                dangerouslySetInnerHTML={{ __html: mailSvg }}
                style={{
                  fontSize: "20px",
                  marginTop: "1vh",
                  marginRight: "0.7vw",
                  opacity: "0.3",
                }}
                // className="ql-editor"
              />
              <span
                dangerouslySetInnerHTML={{ __html: bellSvg }}
                style={{
                  fontSize: "20px",
                  marginTop: "1vh",
                  marginRight: "0.7vw",
                  opacity: "0.3",
                }}
                // className="ql-editor"
              />
            </>

            <UserMenu />
          </div>
        </Header>
      </div>

      <div className={styles.contentParent}>
        <div
          className={isMobile ? styles.mobileSide : styles.side}
          style={{ zIndex: isModalOpen ? "unset" : 999 }}
        >
          <div
            className={collapsed ? styles.collapsed : styles.expand}
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              border: "none",
              height: "100%",
              ...(isMobile && collapsed
                ? {
                    transform: "translateX(-100%)",
                    transition: "transform 0.3s ease",
                  }
                : {}),
            }}
          >
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={collapsed ? styles.sideNav : styles.sideNavItems}
            >
              <Sider trigger={null} collapsible collapsed={collapsed}>
                <Menu
                  style={{ border: "none" }}
                  mode="inline"
                  items={menuList}
                  selectedKeys={[selectedItem]}
                  defaultSelectedKeys={[selectedItem]}
                >
                  {menuItems().map((menuItem) => (
                    <Menu.SubMenu key={menuItem.key} title={menuItem.label}>
                      {menuItem?.children?.map((childItem) => (
                        <Menu.Item key={childItem.key}>
                          {childItem.label}
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  ))}
                </Menu>
              </Sider>
            </div>
          </div>
        </div>

        <div className={isMobile ? styles.mobileContent : styles.content}>
          <Layout style={{ height: "100%" }}>
            <Content
              style={{
                background: "#F5F6F8",
                padding: isMobile ? 10 : 30,
                paddingBottom: 0,
              }}
            >
              {children}
            </Content>
          </Layout>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
