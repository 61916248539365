import { Icon, Title } from "components/common";
import { renderQuillHtml } from "configs/utils";
import theme from "utils/themeVars";
import QuestionFormOptions from "../question/questionFormOptions";
import TagsWithPlus from "../tagswithPlus/tagswithPlus";
import ImageSlider from "components/common/slider/slider";

const QuestionItem = ({ question }) => {
  const renderOption = (item, index) => {
    const isCorrectAnswer = question?.correctAnswer?.includes(item?.answerId);
    const alphabetIndex = String.fromCharCode(65 + index);
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "10px auto",
            gap: "10px",
            alignItems: "center",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: theme["blue50"],
              color: theme["blue200"],
            }}
          >
            {alphabetIndex}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              width: "100%",
              border: `1px solid ${
                isCorrectAnswer ? "#4ca64c" : theme["blue50"]
              }`,
              backgroundColor: isCorrectAnswer ? "#e5f2e5" : "white",
              borderRadius: "10px",
              paddingRight: "5px",
            }}
          >
            {renderQuillHtml(item?.answer)}
            {isCorrectAnswer && (
              <Icon icon="MdOutlineCheckCircle" size={20} color="#4ca64c" />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {question?.images?.[0] && (
        <div>
          <Title level={4}>Image</Title>
          <ImageSlider
            data={[{ type: "image", url: question?.images?.[0] }]}
            itemToShow={1}
            itemHeight={150}
            itemWidth={150}
          />
        </div>
      )}
      {question?.options.map((e, index) => renderOption(e, index))}
      {question?.additionalInfo && (
        <div>
          <Title level={4}>Explanation</Title>
          {renderQuillHtml(question?.additionalInfo, undefined, {
            margin: "-10px 0px -10px -10px",
          })}
        </div>
      )}
      <Title style={{ marginTop: "1%" }} level={5}>
        Question ID: {question?.questionId}
      </Title>
    </>
  );
};

export default QuestionItem;
