import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  DataTable,
  Dropdown,
  Group,
  Icon,
  Modal,
  SelectInput,
  Tag,
  TextInput,
  Title,
  Tooltip,
  notification,
} from "components/common";
import PrimaryButton from "components/common/button/primary-button";
import { ActionButtons, Filters } from "components/custom";
import {
  SCRIPT_EDIT_ICON,
  ScriptLogo,
  TABLE_DELETE_ICON,
} from "components/svg";
import { EDIT_SCRIPT_PATH, TEMPLATE_DETAIL_PATH } from "constants/app-paths";
import {
  useDeleteScriptMutation,
  useGetAllScriptsQuery,
  useGetPageTopicsQuery,
  useGetPlatformsQuery,
  useGetScriptTypesListQuery,
  useGetSearchListingScriptTopicsQuery,
  useGetSpecialitiesQuery,
  useGetTopicsQuery,
  useMarkScriptAsFavoriteMutation,
} from "features/script/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { lowerCaseFirstLetter } from "utils";
import theme from "utils/themeVars";
import { useResetPresentationMutation } from "../../../presentation/apis";
import { canUserModify } from "../../../presentation/utils";
import styles from "../index.module.less";

const multipleModes = {
  page_name: false,
  type: true,
  topics: true,
  speciality: true,
  pageTopics: true,
  is_advance_search: false,
  page: false,
  per_page: false,
};

function processObject(inputObj) {
  // Filter out keys with empty or undefined values
  const filteredObj = Object.fromEntries(
    Object.entries(inputObj).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  // Convert comma-separated values to arrays
  const processedObj = Object.fromEntries(
    Object.entries(filteredObj).map(([key, value]) => {
      return [key, multipleModes?.[key] ? value.split(",") : value];
    })
  );

  return processedObj;
}

const ScriptListing = () => {
  const [pageSizeSave, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);
  const [showAdvanceFilters, setAdvanceFilters] = useState(false);
  const user = useSelector((state) => state.app.user?.info);
  const [filters, setFilters] = useState(queryObj);
  const [sortOrder, setSortOrder] = useState({
    key: "",
    order: "asc",
  });
  let { data, isFetching, isLoading, error, refetch } = useGetAllScriptsQuery(
    {
      body: {
        ...processObject(queryObj),
        is_advance_search: !!showAdvanceFilters,
        sortBy: sortOrder.key,
        sortOrder: sortOrder.order,
        per_page: pageSizeSave ? pageSizeSave : 10,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: options } = useGetScriptTypesListQuery();
  const { data: platforms } = useGetPlatformsQuery();
  const { data: sections } = useGetTopicsQuery(false);
  const { data: pageTopics } = useGetSearchListingScriptTopicsQuery(true);
  const { data: specialties } = useGetSpecialitiesQuery();
  const { data: allPageTopics, isLoading: allPageTopicsLoading } =
    useGetPageTopicsQuery("");

  const handleSpecialityChange = (value) => {
    if (value) {
      if (queryObj?.speciality?.includes(value)) {
      } else {
        setSearchParams((prev) => ({
          ...prev,
          ...filters,
          speciality: value,
        }));
      }
    }
  };

  const handleSort = (key) => {
    let order = "asc";
    if (sortOrder.key === key && sortOrder.order === "asc") {
      order = "dsc";
    }
    setSortOrder({ key, order });

    refetch();
  };

  const getSortIcon = (key) => {
    return sortOrder.key === key && sortOrder.order === "asc" ? (
      <CaretUpFilled />
    ) : (
      <CaretDownFilled />
    );
  };

  const getColumnFilterSpecialties = () => {
    if (specialties?.data?.length) {
      let selected = specialties?.data?.filter((e) =>
        queryObj?.speciality?.includes(e?.topic)
      );
      let unSelected = specialties?.data?.filter(
        (e) => !queryObj?.speciality?.includes(e?.topic)
      );
      return [...selected, ...unSelected];
    }
    return [];
  };

  const libraryTableColumns = [
    {
      title: "No.",
      dataIndex: "key",
      className: "tableStartSpace",
      width: 50,
    },
    {
      title: (
        <span onClick={() => handleSort("page_name")}>
          Page Name {getSortIcon("page_name")}
        </span>
      ),
      dataIndex: "name",
      width: 300,
    },
    {
      title: (
        <span onClick={() => handleSort("page_id")}>
          Page ID {getSortIcon("page_id")}
        </span>
      ),
      dataIndex: "pageId",
      width: 80,
    },
    {
      title: "Primary Specialty",
      dataIndex: "primarySpeciality",
      width: 200,
    },
    {
      title: "Secondary Specialties",
      filterIcon: () => (
        <Icon
          icon={
            !!queryObj?.speciality ? "BsFilterCircleFill" : "BsFilterCircle"
          }
          color={!!queryObj?.speciality ? theme["primary-color"] : ""}
          size={20}
        />
      ),
      filterDropdown: ({ confirm }) => (
        <div style={{ maxHeight: 200, overflowY: "auto" }}>
          {specialties?.data?.length ? (
            getColumnFilterSpecialties().map((e) => (
              <div
                key={e?.topic}
                onClick={() => {
                  handleSpecialityChange(e?.topic);
                  confirm();
                }}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  color: queryObj?.speciality?.includes(e?.topic)
                    ? theme["primary-color"]
                    : "black",
                  backgroundColor: queryObj?.speciality?.includes(e?.topic)
                    ? theme["primary-light"]
                    : "white",
                }}
              >
                {e?.topic}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      ),
      dataIndex: "speciality",
      width: 200,
    },
    { title: "Actions", dataIndex: "actions", width: 100 },
  ];

  useEffect(() => {
    setPageSize(queryObj.per_page ? queryObj.per_page : 10);
  }, [queryObj.page, queryObj.per_page]);

  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(queryObj));
    if (temp?.page_name) delete temp.page_name;
    if (temp?.page) delete temp.page;
    if (temp?.per_page) delete temp.per_page;
    if (Object.keys(temp)?.length) setAdvanceFilters(true);
  }, [JSON.stringify(queryObj)]);

  const modelRef = useRef();
  const [action, setAction] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteScript] = useDeleteScriptMutation();
  const [markAsFavorite] = useMarkScriptAsFavoriteMutation();

  const [resetPresentation] = useResetPresentationMutation();

  const navigate = useNavigate();
  const getActionsList = (e) => [
    {
      key: "edit_presentation",
      icon: <SCRIPT_EDIT_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["blue200"]
        : theme["gray500"],
      content: "Edit Template",
      title: "Edit Template",
      className: "pointer",
      onClick: () => {
        const script = data?.list?.filter(
          (template) => template?._id === selectedRow
        );
        navigate(
          `${EDIT_SCRIPT_PATH.replace(":name", e?.page_name)}?editorId=${
            e?.editor_template_id
          }&version=${e?.version}`
        );
      },
    },
    {
      key: "delete_presentation",
      icon: <TABLE_DELETE_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["red"]
        : theme["gray500"],
      content: "Delete Script",
      title: "Delete Script",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      onClick: () => {
        setAction("Delete");
        setSelectedRow(e._id);
        modelRef.current.setVisible(true);
      },
    },
  ];

  const getItems = useCallback(() => {
    return options?.length
      ? options?.map((e) => ({
          label: e?.name,
          key: e?._id,
          onClick: () => {
            navigate(`/scripts/add?template_id=${e?.base_template_id}`);
          },
        }))
      : [];
  }, [options]);

  const markFavorite = async (script) => {
    const res = await markAsFavorite({
      id: script?.editor_template_id,
      userId: user?._id,
    });
    if (res) {
      if (res?.error) {
        notification.error({ message: res?.error });
      } else {
        notification.success({ message: "Updated Successfully" });
      }
    }
  };

  const isFavoriteOfUser = (favorites) => {
    if (favorites?.length) {
      const index = favorites.findIndex((e) => e == user?._id);
      return index != -1;
    } else {
      return false;
    }
  };

  const tableData = () => {
    return data?.list?.length
      ? data.list.map((e, index) => ({
          key: index + 1,
          page_name: e?.page_name,
          page_id: e?.page_id,
          name: (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div>
                <Icon
                  icon={
                    isFavoriteOfUser(e?.favorites)
                      ? "AiFillStar"
                      : "AiOutlineStar"
                  }
                  color="#F5B301"
                  size="22px"
                  style={{ cursor: "pointer" }}
                  tooltipText={
                    isFavoriteOfUser(e?.favorites)
                      ? "Click to remove from  Favorites"
                      : "Click to add to Favorites"
                  }
                  onClick={() => {
                    markFavorite(e);
                  }}
                />
              </div>
              <div
                style={{ color: theme["primary-color"] }}
                className="pointer"
                onClick={() =>
                  navigate(
                    `${TEMPLATE_DETAIL_PATH.replace(
                      ":name",
                      e?.page_name
                    )}?editorId=${e?.editor_template_id}&version=${e?.version}`
                  )
                }
              >
                {e?.page_name}
              </div>
            </div>
          ),
          speciality: (
            <>
              {e?.speciality?.length > 3 ? (
                <>
                  {e.speciality.map((item, index) => {
                    return index < 3 ? (
                      <Tag key={index} style={{ marginBottom: "0.5vh" }}>
                        {item}
                      </Tag>
                    ) : null;
                  })}
                  <Tooltip
                    placement="top"
                    title={e.speciality.map((item, index) => {
                      return index > 2 ? <div>{item}</div> : null;
                    })}
                  >
                    <Tag key={"more"} style={{ marginBottom: "0.5vh" }}>
                      {`+${e.speciality?.length - 3}`}
                    </Tag>
                  </Tooltip>
                </>
              ) : (
                <>
                  {e.speciality.map((item, index) => (
                    <Tag key={index} style={{ marginBottom: "0.5vh" }}>
                      {item}
                    </Tag>
                  ))}
                </>
              )}
            </>
          ),
          primarySpeciality: e?.primarySpeciality,
          pageId: <div>{e?.page_id}</div>,

          actions: <ActionButtons list={getActionsList(e)} />,
        }))
      : [];
  };

  const changeAdvanceFilterStatus = () => {
    setSearchParams({});
    setAdvanceFilters((prev) => !prev);
    setFilters(null);
  };

  const advancedFilterList = useMemo(() => {
    return [
      {
        label: "none",
        list: platforms?.data.map((plat) => ({
          label: plat,
          value: lowerCaseFirstLetter(plat),
        })),
        type: "select",
        key: "type",
        mode: "multiple",
        placeholder: "Select platforms",
      },
      {
        label: "none",
        list: sections?.data.map((plat) => ({
          label: plat.topic,
          value: plat.topic,
        })),
        type: "select",
        mode: "multiple",
        key: "topics",
        placeholder: "Select sections",
      },
      {
        label: "none",
        mode: "multiple",
        list: specialties?.data.map((plat) => ({
          label: plat.topic,
          value: plat.topic,
        })),
        type: "select",
        key: "speciality",
        placeholder: "Select specialties",
      },
      {
        label: "none",
        list: pageTopics?.data.map((plat) => ({
          label: plat.PageTopics,
          value: plat.PageTopics,
        })),
        type: "select",
        mode: "multiple",
        key: "pageTopics",
        placeholder: "Select topics",
      },
    ];
  }, [platforms, sections, specialties, pageTopics]);

  const getAllFilters = (
    filterTypeObj,
    selectedFilter,
    filterTypeKey,
    prevFilters
  ) => {
    const tempFilters = { ...prevFilters };
    if (selectedFilter?.length) {
      tempFilters[filterTypeKey] = selectedFilter
        ?.map((e) => e?.value)
        ?.join(",");
    } else if (tempFilters?.[filterTypeKey]) {
      delete tempFilters[filterTypeKey];
    }
    delete tempFilters?.page;
    setFilters((prev) => ({
      ...(prev?.page_name ? { page_name: prev?.page_name } : {}),
      ...tempFilters,
    }));
  };

  const renderSimpleFilters = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          {showAdvanceFilters ? (
            <TextInput
              flexDirection="column"
              labelClass={styles.labelClass}
              labelInputParent={styles.labelInputParent}
              labelProps={{}}
              label="none"
              placeholder="Search by Keyword"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setSearchParams(filters);
                }
              }}
              onChange={(e) => {
                setFilters((prev) => {
                  let fil = { ...prev };
                  delete fil.page;
                  return {
                    ...fil,
                    page_name: e?.target?.value,
                  };
                });
              }}
              value={filters?.page_name}
            />
          ) : (
            <SelectInput
              placeholder="Search By Page"
              allowClear
              options={
                allPageTopics?.length
                  ? allPageTopics?.map((e) => ({
                      ...e,
                      key: Math.random().toString(),
                    }))
                  : []
              }
              inputLoading={allPageTopicsLoading}
              value={filters?.page_name}
              getOptionLabel={(e) => e?.page_name}
              getOptionValue={(e) => e?.page_name}
              style={{
                width: "100%",
                // queryObj && Object.keys(queryObj)?.length ? "75vw" : "80vw",
                marginTop: "15px",
              }}
              onChange={(value) => {
                setFilters((prev) => {
                  let fil = { ...prev };
                  delete fil.page;
                  return {
                    ...fil,
                    page_name: value,
                  };
                });
              }}
            />
          )}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          {queryObj && Object.keys(queryObj)?.length ? (
            <div className={styles.clearAll}>
              <span
                className={styles.clearAllText}
                onClick={() => {
                  setFilters(null);
                  setSearchParams({});
                }}
              >
                {" "}
                Clear All{" "}
              </span>
            </div>
          ) : null}

          <div>
            <Button
              onClick={() => {
                setSearchParams(filters);
              }}
              text="Search"
              icon={"FiSearch"}
              type="primary"
              size={"large"}
              className="pi-12"
              style={{ height: 48, boxShadow: "none", marginTop: "1.5vh" }}
              loading={false}
            />
          </div>
          <div>
            <Button
              onClick={changeAdvanceFilterStatus}
              icon={
                showAdvanceFilters ? (
                  <FaAngleDoubleUp color="#C4D8F5" />
                ) : (
                  <FaAngleDoubleDown color="#C4D8F5" />
                )
              }
              type="primary"
              size={"large"}
              className="pi-12"
              style={{
                height: 48,
                boxShadow: "none",
                backgroundColor: "#f7faff",
                marginTop: "1.5vh",
              }}
              loading={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div>
        {renderSimpleFilters()}
        {showAdvanceFilters && (
          <div style={{}}>
            <Filters
              list={advancedFilterList || []}
              loading={false}
              skeletonLoading={false}
              showSearchButton={false}
              showClearAll={false}
              onFilterValueChange={getAllFilters}
            />
          </div>
        )}
      </div>
    );
  };

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>Scripts</Title>
        <Dropdown
          options={getItems()}
          trigger="click"
          arrow={false}
          suffixIcon=""
          style={{
            color: theme["blue200"],
            background: theme["light-gray"],
            fontWeight: 800,
            fontSize: "1.2rem",
            padding: "12px, 12px, 13px, 12px",
            borderRadius: "4px",
            borderColor: theme["blue100"],
          }}
        >
          <PrimaryButton>
            <ScriptLogo color="1565DC" />
            Add
          </PrimaryButton>
        </Dropdown>
      </div>
    );
  };

  const renderPresentationWeb = () => {
    return (
      <Group gap="16px" className={"listings-content-adjustments"}>
        <Card>
          {renderPageHeader()}
          {renderFilters()}
        </Card>
        <DataTable
          pagination={{
            totalCount: data?.pagination?.totalCount,
            pageOptions: [10, 25, 50],
          }}
          paginationOnBottom={true}
          onChangePage={(queryObj) => setSearchParams(queryObj)}
          columns={libraryTableColumns}
          data={tableData()}
          loading={isLoading}
          error={error}
          onErrorRetry={refetch}
          skeletonLoading={isFetching}
          stickyOffset={0}
          noUrlPush={false}
        />
      </Group>
    );
  };

  return (
    <>
      {/* {isMobile ? renderPresentationMob() : renderPresentationWeb()} */}
      {renderPresentationWeb()}
      <Modal
        ref={modelRef}
        onOk={() => {
          if (!action) {
            return;
          }
          if (action == "Delete") {
            if (selectedRow) {
              const script = data?.list?.filter(
                (template) => template?._id === selectedRow
              );
              const hasPermission =
                user?.user_role?.toLowerCase() === "admin" ||
                user?._id === script[0]?.editor_id;
              if (!hasPermission) {
                notification.error({
                  message: "You have privilege to delete script",
                });
                return;
              }
              deleteScript(selectedRow)
                .unwrap()
                .then(() => {
                  notification.success({
                    message: "Script has been removed!",
                  });
                })
                .catch((e) => {
                  notification.error({
                    message: e?.message,
                  });
                });
            }
          } else if (action == "Reset") {
            resetPresentation(selectedRow)
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Presentation has been reset!",
                });
              })
              .catch((e) => {
                notification.error({
                  message: e?.message,
                });
              });
          }
          modelRef.current.setVisible(false);
          setAction(null);
          setSelectedRow(null);
          refetch();
        }}
        onCancel={() => modelRef.current.setVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
        title={`${action} Template`}
      >
        Are you sure you want to {action} this template?
      </Modal>
    </>
  );
};

export default ScriptListing;
