import { Tooltip } from "antd";
import { Title, notification } from "components/common";
import { PIN, SCRIPT_EDIT_ICON, TABLE_DELETE_ICON } from "components/svg";
import { useEffect, useState } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setScriptPodCastData } from "store/scriptSlice";
import Button from "../button/button";
import Image from "../image/image";
import styles from "./index.module.less";

const ImageSlider = ({
  data,
  itemToShow,
  itemHeight,
  onEdit = () => {},
  onDelete = () => {},
  onPreview = () => {},
  showActions = false,
  viewOnly = false,
  hideRemove = false,
  onEditVideo = () => {},
  actions = ["preview", "delete", "edit"],
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videosCurrentIndex, setVideosCurrentIndex] = useState(0);
  const [podcastCurrentIndex, setpodcastCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.app.user.info);

  useEffect(() => {
    const lastIndex = data.length - itemToShow;
    setCurrentIndex((prevIndex) =>
      prevIndex > lastIndex ? lastIndex : prevIndex
    );
    setVideosCurrentIndex((prevIndex) =>
      prevIndex > lastIndex ? lastIndex : prevIndex
    );
    setpodcastCurrentIndex((prevIndex) =>
      prevIndex > lastIndex ? lastIndex : prevIndex
    );
  }, [data.length, itemToShow]);

  // For Images

  const nextSlide = () => {
    const lastIndex = data.length - itemToShow;
    setCurrentIndex((prevIndex) => (prevIndex < lastIndex ? prevIndex + 1 : 0));
  };

  const prevSlide = () => {
    const lastIndex = data.length - itemToShow;
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : lastIndex));
  };

  //For Videos

  const videoMedia = data?.filter((obj) => obj.type === "licenseVideo");

  const videosNextSlide = () => {
    const lastIndex = videoMedia?.length - itemToShow;
    setVideosCurrentIndex((prevIndex) =>
      prevIndex < lastIndex ? prevIndex + 1 : 0
    );
  };

  const videosPrevSlide = () => {
    const lastIndex = videoMedia?.length - itemToShow;
    setVideosCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : lastIndex
    );
  };

  //For Podcasts

  // Initialize the state with an empty array
  let podCasts = [];
  const [podcastMedia, setPodcastMedia] = useState([]);

  // Later, when you want to update podcastMedia with filtered data, use useEffect
  useEffect(() => {
    // Filter the data and set the state
    podCasts = data?.filter((obj) => obj.type === "licensePodcast");
    setPodcastMedia(podCasts);
  }, [data]);

  const podcastNextSlide = () => {
    const lastIndex = podcastMedia?.length - itemToShow;
    setpodcastCurrentIndex((prevIndex) =>
      prevIndex < lastIndex ? prevIndex + 1 : 0
    );
  };

  const podcastPrevSlide = () => {
    const lastIndex = podcastMedia?.length - itemToShow;
    setpodcastCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : lastIndex
    );
  };

  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  // For Images
  const slideWidth = 100 / itemToShow;
  const sliderWidth = slideWidth * data.length;
  const translateXValue = -(currentIndex * slideWidth) + "%";
  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < data.length - itemToShow;

  // For Videos
  // const slideWidth = 100 / itemToShow;
  // const sliderWidth = slideWidth * data.length;
  const translateXValueVideos = -(videosCurrentIndex * slideWidth) + "%";
  const hasPreviousVideos = videosCurrentIndex > 0;
  const hasNextVideos = videosCurrentIndex < videoMedia?.length - itemToShow;

  // For Podcast
  const translateXValuePodcast = -(podcastCurrentIndex * slideWidth) + "%";
  const hasPreviousPodcast = podcastCurrentIndex > 0;
  const hasNextPodcast =
    podcastCurrentIndex < podcastMedia?.length - itemToShow;

  const handleRemove = (item, index) => {
    console.log("===media");
    podCasts = podcastMedia?.filter((media) => {
      return media?.id !== item?.id;
    });
    setPodcastMedia(podCasts);
    dispatch(setScriptPodCastData(podCasts));
  };

  const handlePinnedItem = (item, index) => {
    const updatedPodcastMedia = [...podcastMedia];
    let updatedItem = { ...item };

    if (updatedItem.hasOwnProperty("isPinned")) {
      updatedItem.isPinned = !updatedItem.isPinned;
      notification.success({
        message: "Podcast Un Pinned",
      });
    } else {
      updatedItem.isPinned = true;
      notification.success({
        message: "Podcast Pinned",
      });
    }

    if (!updatedItem.hasOwnProperty("pinnedBy")) {
      updatedItem.pinnedBy = [];
    }
    if (updatedItem.isPinned && userInfo && userInfo._id) {
      let users = [];
      users = JSON.parse(JSON.stringify(updatedItem.pinnedBy));
      users.push(userInfo?._id);
      updatedItem.pinnedBy = users;
    } else {
      updatedItem.pinnedBy = updatedItem.pinnedBy.filter(
        (uId) => uId !== userInfo?._id
      );
    }

    updatedPodcastMedia[index] = updatedItem;

    sortPinnedItems(updatedPodcastMedia);
  };
  const sortPinnedItems = (podcastMedia) => {
    const prioritizeByPinned = (podcastMedia) => {
      const pinnedItems = podcastMedia.filter(
        (item) => item.pinnedBy && item.pinnedBy.includes(userInfo?._id)
      );

      const unpinnedItems = podcastMedia.filter(
        (item) => !item.pinnedBy || !item.pinnedBy.includes(userInfo?._id)
      );

      return [...pinnedItems, ...unpinnedItems];
    };

    const prioritizedPodcastMedia = prioritizeByPinned(
      podcastMedia,
      userInfo?.id
    );

    setPodcastMedia(prioritizedPodcastMedia);
    dispatch(setScriptPodCastData(prioritizedPodcastMedia));
  };

  const getOptions = (item, index) => {
    return showActions ? (
      hovered ? (
        <div
          key={`actions-${index + 100}`}
          // className={"ant-image-mask"}
          style={{ display: "flex", gap: "2%" }}
        >
          {!viewOnly ? (
            <>
              {actions?.find((e) => e == "preview")?.length && (
                <Tooltip title="Preview">
                  <Button icon={"IoInformationCircleOutline"} />
                </Tooltip>
              )}
              {actions?.find((e) => e == "edit")?.length && (
                <Tooltip title="Edit">
                  <Button
                    icon={"AiOutlineEdit"}
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit({ ...item, edit: true, editIndex: index });
                    }}
                  />
                </Tooltip>
              )}
              {actions?.find((e) => e == "delete")?.length && (
                <Tooltip title="Delete">
                  <Button
                    icon={"TABLE_DELETE_ICON"}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(item, index);
                    }}
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip title="Preview">
              <Button
                icon={"IoInformationCircleOutline"}
                onClick={(e) => {
                  e.stopPropagation();
                  onPreview(item, index);
                }}
              />
            </Tooltip>
          )}
        </div>
      ) : (
        <></>
      )
    ) : (
      "Preview"
    );
  };

  function getYouTubeEmbedCode(url) {
    if (url?.includes("/embed/")) {
      return url;
    }

    const watchUrlMatch = url?.match(/[?&]v=([^&]+)/);
    const watchVideoId = (watchUrlMatch && watchUrlMatch[1]) || "";
    if (watchVideoId) {
      return `https://www.youtube.com/embed/${watchVideoId}`;
    }

    const videoUrlMatch = url?.match(/\/watch\?v=([^&]+)/);
    const videoId = (videoUrlMatch && videoUrlMatch[1]) || "";
    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    }
    return url;
  }

  const iFrameHeight = () => {
    // if (podcastMedia?.length <= 2) {
    //   return 55;
    // } else if (podcastMedia?.length > 2 && podcastMedia?.length <= 5) {
    //   return 50;
    // } else {
    //   return 48;
    // }
    return 50;
  };

  const getEmbededCode = (url, ImageURL) => {
    const source = url?.length ? url : ImageURL;
    // Regular expression to extract the URL from src attribute
    const regex = /src="([^"]+)"/;
    const match = source?.match(regex);
    let srcUrl = "";

    if (match) {
      srcUrl = match[1];
    } else {
      console.log("No match found.");
    }
    return srcUrl;
  };

  return data?.length > itemToShow ? (
    <>
      {/* For Images  */}
      <div className={styles.imageSlider}>
        <div
          className={styles.sliderWrapper}
          style={{ height: `${itemHeight}px`, overflow: "hidden" }}
        >
          <div
            className={styles.slider}
            style={{
              transform: `translateX(${translateXValue})`,
              width: `${sliderWidth - 1}%`,
              display: "flex",
            }}
          >
            {data.map((item, index) => {
              let renderedItem = <></>;
              if (
                item?.type?.includes("image") ||
                item?.type?.includes("Image")
              ) {
                renderedItem = (
                  <div
                    key={index}
                    style={{
                      border: "1px solid #f0f0f0",
                      height: `${itemHeight}px`,
                      width: `${slideWidth - 1}%`,
                      flex: `0 0 ${slideWidth - 1}%`,
                    }}
                    className="pointer"
                    onMouseEnter={() => handleMouseEnter(item)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Image
                      src={item?.url || item?.ImageURL}
                      alt={`slide-${index}`}
                      height={"100%"}
                      width={`100%`}
                      style={{
                        objectFit: "fill",
                      }}
                      preview={{
                        mask: getOptions(item, index),
                      }}
                    />
                  </div>
                );
              }
              return renderedItem;
            })}
          </div>
        </div>
        {hasPrevious &&
          data?.filter((e) => e?.type == "image" || e?.type == "licenseImage")
            ?.length > itemToShow && (
            <Button
              icon={"FaArrowLeft"}
              className={`${styles.arrow} ${styles.left}`}
              onClick={prevSlide}
              shape="circle"
              size="large"
            />
          )}

        {hasNext &&
          data?.filter((e) => e?.type == "image" || e?.type == "licenseImage")
            ?.length > itemToShow && (
            <Button
              icon={"FaArrowRight"}
              className={`${styles.arrow} ${styles.right}`}
              onClick={nextSlide}
              shape="circle"
              size="large"
            />
          )}
      </div>
      {/* For Videos */}
      <div style={{ position: "relative" }}>
        <div style={{ position: "fixed", top: "-10px", right: "-10px" }}>
          test
        </div>
        <div className={styles.videoSlider}>
          <div
            className={styles.sliderWrapper}
            style={{ height: `${itemHeight}px`, overflow: "hidden" }}
          >
            <div
              className={styles.slideVideo}
              style={{
                transform: `translateX(${translateXValueVideos})`,
                width: `${sliderWidth - 1}%`,
                display: "flex",
              }}
            >
              {data.map((item, index) => {
                let renderedItem = <></>;
                if (
                  item?.type?.includes("video") ||
                  item?.type?.includes("Video")
                ) {
                  renderedItem = (
                    <div
                      key={index}
                      style={{
                        border: "1px solid #f0f0f0",
                        height: itemHeight,
                        width: `${slideWidth - 1}%`,
                        flex: `0 0 ${slideWidth - 1}%`,
                      }}
                      className="pointer"
                      onClick={() => onClickItem(item)}
                    >
                      <iframe
                        id={`${index}-video`}
                        type="text/html"
                        src={getYouTubeEmbedCode(
                          item?.url || item?.VideoIframe
                        )}
                        frameborder="0"
                        height={"100%"}
                        width={"100%"}
                        allow="fullscreen"
                      ></iframe>
                    </div>
                  );
                }
                return renderedItem;
              })}
            </div>
          </div>
        </div>

        {hasPreviousVideos && (
          <Button
            icon={"FaArrowLeft"}
            className={`${styles.arrow} ${styles.left}`}
            onClick={videosPrevSlide}
            shape="circle"
            size="large"
          />
        )}

        {hasNextVideos && (
          <Button
            icon={"FaArrowRight"}
            className={`${styles.arrow} ${styles.right}`}
            onClick={videosNextSlide}
            shape="circle"
            size="large"
          />
        )}
      </div>
      {/* For Podcast */}
      <div
        style={{
          margin: "10px",
        }}
        className={styles.videoSlider}
      >
        <div
          className={styles.sliderWrapper}
          style={{ height: `${itemHeight}px`, overflow: "hidden", margin: 0 }}
        >
          <div
            className={styles.slider}
            style={{
              transform: `translateX(${translateXValuePodcast})`,
              width: `${sliderWidth - 1}%`,
              display: "flex",
              margin: "20px 0px 0px 0px",
            }}
          >
            {podcastMedia?.map((item, index) => {
              if (
                item?.type?.includes("podcast") ||
                item?.type?.includes("Podcast")
              ) {
                return (
                  <div
                    key={index}
                    style={{
                      width: `${slideWidth - 1}%`,
                      flex: `0 0 ${slideWidth - 1}%`,
                      margin: 0,
                    }}
                    className="pointer"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "-2vh",
                        gap: "15px",
                      }}
                    >
                      {!hideRemove && (
                        <Tooltip title="Delete">
                          <div
                            onClick={() => handleRemove(item, index)}
                            className={styles.circle}
                          >
                            <TABLE_DELETE_ICON />
                          </div>
                        </Tooltip>
                      )}
                      {!hideRemove && (
                        <Tooltip title="Pin/UnPin">
                          <div
                            onClick={() => handlePinnedItem(item, index)}
                            className={styles.circle}
                          >
                            <PIN clr={item?.isPinned ? "#1565DC" : "#808080"} />
                          </div>
                        </Tooltip>
                      )}
                    </div>

                    <iframe
                      style={{
                        width: "100%",
                        height: `30vh`,
                        marginTop: "10px",
                      }}
                      title="Spotify Player"
                      src={getEmbededCode(item?.url, item?.ImageURL)}
                      frameborder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                      loading="eager"
                      scrolling="no"
                    ></iframe>
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </div>
        {hasPreviousPodcast && (
          <Button
            icon={"FaArrowLeft"}
            className={`${styles.arrow} ${styles.left}`}
            onClick={podcastPrevSlide}
            shape="circle"
            size="large"
          />
        )}

        {hasNextPodcast && (
          <Button
            icon={"FaArrowRight"}
            className={`${styles.arrow} ${styles.right}`}
            onClick={podcastNextSlide}
            shape="circle"
            size="large"
          />
        )}
      </div>
      {/*For Files */}
      <div className={styles.imageSlider}>
        <div
          className={styles.sliderWrapper}
          style={{ height: `${itemHeight}px`, overflow: "hidden" }}
        >
          <div
            className={styles.slider}
            style={{
              transform: `translateX(${translateXValue})`,
              width: `${sliderWidth - 1}%`,
              display: "flex",
            }}
          >
            {data.map((item, index) => {
              let renderedItem = <></>;
              if (item?.type?.includes("file")) {
                renderedItem = (
                  <>
                    <div
                      key={index}
                      style={{
                        border: "1px solid #f0f0f0",
                        height: itemHeight,
                        width: `${slideWidth - 1}%`,
                        flex: `0 0 ${slideWidth - 1}%`,
                        paddingTop: "1%",
                        paddingBottom: "1%",
                      }}
                      className="pointer"
                      onClick={() => onClickItem(item)}
                    >
                      <FaRegFileAlt
                        style={{ height: "80%", width: "100%", color: "black" }}
                      />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        File Name
                      </div>
                    </div>
                  </>
                );
              }
              return renderedItem;
            })}
          </div>
        </div>
        {false && (
          <Button
            icon={"FaArrowLeft"}
            className={`${styles.arrow} ${styles.left}`}
            onClick={prevSlide}
            shape="circle"
            size="large"
          />
        )}

        {false && (
          <Button
            icon={"FaArrowRight"}
            className={`${styles.arrow} ${styles.right}`}
            onClick={nextSlide}
            shape="circle"
            size="large"
          />
        )}
      </div>
    </>
  ) : (
    <>
      {/*For Images */}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "12px 12px 12px 0",
          gap: "12px",
        }}
      >
        {data.map((item, index) => {
          if (item?.type?.includes("image") || item?.type?.includes("Image")) {
            return (
              <div
                key={index}
                style={{
                  border: "1px solid #f0f0f0",
                  height: "150px",
                  width: `150px`,
                }}
                className="pointer"
                onMouseEnter={() => handleMouseEnter(item)}
                onMouseLeave={handleMouseLeave}
              >
                <Image
                  src={item?.url || item?.ImageURL}
                  alt={`slide-${index}`}
                  height={"100%"}
                  width={`100%`}
                  style={{
                    objectFit: "fill",
                  }}
                  preview={{
                    mask: getOptions(item, index),
                  }}
                />
              </div>
            );
          }
          return <></>;
        })}
      </div>

      {/* For Videos */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "0 12px 12px 0",
          gap: "60px",
        }}
      >
        {data.map((item, index) => {
          if (item?.type?.includes("video") || item?.type?.includes("Video")) {
            console.log("===item", item);
            return (
              <div
                key={index}
                style={{
                  border: "1px solid #f0f0f0",
                  height: "150px",
                  width: `150px`,
                  position: "relative",
                }}
                className="pointer"
                // onClick={() => onClickItem(item)}
              >
                {!viewOnly && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-35px",
                      left: "0px",
                      width: "200px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Title>
                          {item?.imageTitle?.length < 8
                            ? item?.imageTitle
                            : `${item?.imageTitle?.slice(0, 7)}...`}
                        </Title>
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Button
                          type="light"
                          icon={<SCRIPT_EDIT_ICON />}
                          shape="circle"
                          onClick={(e) => {
                            onEditVideo({
                              type: "licenseVideo",
                              ...item,
                              edit: true,
                              editIndex: index,
                            });
                          }}
                        />
                        <Button
                          type="light"
                          icon={<TABLE_DELETE_ICON />}
                          shape="circle"
                          onClick={(e) => {
                            onDelete(item, index);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <iframe
                  id={`${index}-video`}
                  type="text/html"
                  src={getYouTubeEmbedCode(item?.url || item?.VideoIframe)}
                  frameborder="0"
                  height={200}
                  width={200}
                  allow="fullscreen"
                ></iframe>
              </div>
            );
          }
          return <></>;
        })}
      </div>

      {/* For Files */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "12px 12px 12px 0",
          gap: "12px",
        }}
      >
        {data.map((item, index) => {
          if (item?.type?.includes("file")) {
            return renderFile(item, index);
          }
          return <></>;
        })}
      </div>

      {/* For Podcast */}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "12px 12px 12px 0",
          gap: "12px",
        }}
      >
        {podcastMedia?.map((item, index) => {
          if (
            item?.type?.includes("podcast") ||
            item?.type?.includes("Podcast")
          ) {
            return (
              <div
                key={index}
                style={{
                  width: `100%`,
                }}
                className="pointer"
              >
                {/* Remove and Pin Button */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "-10px",
                    marginRight: "30px",
                    gap: "10px",
                    zIndex: "900",
                  }}
                >
                  {!hideRemove && (
                    <Tooltip title="Delete">
                      <div
                        onClick={() => handleRemove(item, index)}
                        className={styles.circle}
                      >
                        <TABLE_DELETE_ICON />
                      </div>
                    </Tooltip>
                  )}
                  {!hideRemove && (
                    <Tooltip title="Pin/UnPin">
                      <div
                        onClick={() => handlePinnedItem(item, index)}
                        className={styles.circle}
                      >
                        <PIN clr={item?.isPinned ? "#1565DC" : "#808080"} />
                      </div>
                    </Tooltip>
                  )}
                </div>

                <iframe
                  style={{
                    width: "100%",
                    height: "30vh",
                    marginTop: "1%",
                  }}
                  title="Spotify Player"
                  src={getEmbededCode(item?.url, item?.ImageURL)}
                  frameborder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                  loading="eager"
                  scrolling="no"
                ></iframe>
              </div>
            );
          }
          return <></>;
        })}
      </div>
    </>
  );
};

export default ImageSlider;
