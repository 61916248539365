import { Card } from "components/common";
import {
  useGetPageTopicsQuery,
  useGetSearchListingScriptTopicsQuery,
} from "features/script/api";
import { useState } from "react";
import QuestionCardView from "./questionCardView";
import ViewEditQuestionModal from "./viewEditQuestionModal";
import AddEditQuestionPage from "features/questions/components/questionFormPage";

const ScriptQuestionSection = ({
  scriptEditMode = false,
  detailPage,
  scriptData,
  scriptQuestionsApiData,
}) => {
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const [viewEditModal, setViewEditModal] = useState(false);

  const { data: allScripts, isLoading } = useGetPageTopicsQuery("");
  const { data: allTOpics } = useGetSearchListingScriptTopicsQuery();

  return (
    <Card>
      <QuestionCardView
        handleAdd={() => setAddQuestionModal(true)}
        handleEdit={() => setViewEditModal("edit")}
        handleView={() => setViewEditModal("view")}
        scriptData={scriptData}
        scriptEditMode={scriptEditMode}
        detailPage={detailPage}
      />
      {addQuestionModal && (
        <AddEditQuestionPage
          inScriptForm={true}
          visible={addQuestionModal}
          setVisible={setAddQuestionModal}
        />
      )}
      {viewEditModal && (
        <ViewEditQuestionModal
          viewEditModal={viewEditModal}
          setViewEditModal={setViewEditModal}
          questions={scriptData?.questions}
          explanations={scriptData?.explanations}
          apiData={scriptQuestionsApiData}
          allScripts={allScripts}
          scriptData={scriptData}
          handleAddQuestion={() => {
            setViewEditModal(null);
            setAddQuestionModal(true);
          }}
          detailPage={detailPage}
          allTOpics={allTOpics}
        />
      )}
    </Card>
  );
};

export default ScriptQuestionSection;
