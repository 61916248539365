const { default: parentApi } = require("store/parentApi");

const testApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getQuestionsForTest: build.query({
      query: (body) => {
        return {
          url: `/test/getTest`,
          method: "POST",
          body: body,
        };
      },
    }),
    createTest: build.mutation({
      query: (body) => {
        return {
          url: `/test/createTest`,
          method: "POST",
          body: body,
        };
      },
    }),
    startTest: build.mutation({
      query: (body) => {
        return {
          url: `/test/startTest`,
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useLazyGetQuestionsForTestQuery,
  useCreateTestMutation,
  useStartTestMutation,
} = testApis;
