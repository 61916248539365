import AppUsers from "features/appUsers/components";
import ApprovedPages from "features/approvedPages/components";
import PublicPresentation from "features/auth/publicPresentationViewCall";
import Saved from "features/compare/components/saved";
import Contact from "features/contact/components";
import CreateGraph from "features/createGraphs/components";
import CreateStudySessions from "features/createStudySessions/components";
import StudySessions from "features/createStudySessions/components/studySessions";
import Dashboard from "features/dashboard/components";
import ACCOUNTS from "features/dashboard/components/ACCOUNTS";
import Presentation from "features/presentation/components";
import PresentationDetails from "features/presentation/components/presentationDetailPage";
import PresentationTable from "features/presentation/components/presentationTable";
import QuestionsListing from "features/questions/components";
import AddEditQuestionPage from "features/questions/components/questionFormPage";
import ViewScriptQuestion from "features/questions/components/viewScriptQuestion";
import QuizListing from "features/quiz/listing";
import QuizResult from "features/quiz/result";
import TakeQuiz from "features/quiz/takeQuiz";
import AddScriptForm from "features/script/components/addScriptForm";
import EditedPages from "features/script/components/editedPages";
import FavoriteListing from "features/script/components/favoritesScripts";
import HiddenPages from "features/script/components/hiddenPages";
import MobileScriptListing from "features/script/components/mobileScriptListing";
import ScriptDetails from "features/script/components/scriptDetails";
import ScriptFeedback from "features/script/components/scriptFeedback";
import ScriptListing from "features/script/components/scriptListing";
import ScriptTemplateSettings from "features/templateSettings/components";
import ScriptTemplateSettingsForm from "features/templateSettings/components/templateSettingForm";
import { useSelector } from "react-redux";
import {
  ACCOUNTS_PATH,
  ADD_SCRIPT_PATH,
  APPROVED_PAGES,
  APP_USERS,
  CONTACT_US,
  CREATE_GRAPH,
  CREATE_STUDY_SESSION,
  CREATE_TEST_FORM,
  DEFAULT_PATH,
  EDITED_PAGES,
  EDIT_SCRIPT_PATH,
  FAVORITE_PAGES,
  HIDDEN_PAGES,
  PRESENTATION_ADD_PATH,
  PRESENTATION_DETAIL_PATH,
  PRESENTATION_DETAIL_VIEW_PUBLIC_PATH,
  PRESENTATION_EDIT_PATH,
  PRESENTATION_PATH,
  SAVED,
  SCRIPT,
  SCRIPT_ADD_QUESTIONS,
  SCRIPT_DETAIL_PATH,
  SCRIPT_EDIT_QUESTIONS,
  SCRIPT_FEEDBACK_PATH,
  SCRIPT_QUESTIONS_LISTING,
  SCRIPT_QUIZ_LISTING,
  SCRIPT_QUIZ_PATH,
  SCRIPT_QUIZ_RESULT_PATH,
  SCRIPT_TEMPLATE_SETTINGS_FORM_PATH,
  SCRIPT_TEMPLATE_SETTINGS_PATH,
  SCRIPT_VIEW_QUESTIONS,
  STUDY_SESSION,
} from "./app-paths";
import CreateTestForm from "features/test/createTestForm";
const getAppRoutes = () => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  return [
    {
      path: DEFAULT_PATH,
      element: <Dashboard />,
      name: "dashboard",
    },
    {
      path: ACCOUNTS_PATH,
      element: <ACCOUNTS />,
      name: "accounts",
    },
    {
      path: ADD_SCRIPT_PATH,
      element: <AddScriptForm />,
      name: "addScript",
    },
    {
      path: EDIT_SCRIPT_PATH,
      element: <AddScriptForm />,
      name: "editScript",
    },
    {
      path: SCRIPT_DETAIL_PATH,
      element: <ScriptDetails />,
      name: "scriptDetail",
    },
    {
      path: SCRIPT,
      element: isMobile ? <MobileScriptListing /> : <ScriptListing />,
      name: "script",
    },
    {
      path: PRESENTATION_PATH,
      element: <PresentationTable />,
      name: "presentation",
    },
    {
      path: PRESENTATION_DETAIL_PATH,
      element: <PresentationDetails />,
      name: "presentationDetail",
    },
    {
      path: PRESENTATION_ADD_PATH,
      element: <Presentation />,
      name: "presentationAdd",
    },
    {
      path: PRESENTATION_EDIT_PATH,
      element: <Presentation />,
      name: "presentationEdit",
    },
    {
      path: CONTACT_US,
      element: <Contact />,
      name: "contactus",
    },
    {
      path: APPROVED_PAGES,
      element: <ApprovedPages />,
      name: "approvedpages",
    },
    {
      path: CREATE_GRAPH,
      element: <CreateGraph />,
      name: "creategraph",
    },

    {
      path: CREATE_STUDY_SESSION,
      element: <CreateStudySessions />,
      name: "createStudySessions",
    },
    {
      path: HIDDEN_PAGES,
      element: <HiddenPages />,
      name: "hiddenPages",
    },
    {
      path: EDITED_PAGES,
      element: <EditedPages />,
      name: "editedPages",
    },
    {
      path: FAVORITE_PAGES,
      element: <FavoriteListing />,
      name: "favoritePPages",
    },
    {
      path: APP_USERS,
      element: <AppUsers />,
      name: "app-users",
    },
    {
      path: SCRIPT_FEEDBACK_PATH,
      element: <ScriptFeedback />,
      name: "script-feedback",
    },
    {
      path: SAVED,
      element: <Saved />,
      name: "saved",
    },
    {
      path: STUDY_SESSION,
      element: <StudySessions />,
      name: "studysessions",
    },
    {
      path: PRESENTATION_DETAIL_VIEW_PUBLIC_PATH,
      element: <PublicPresentation />,
      name: "publicPresentationDetails",
    },
    {
      path: SCRIPT_TEMPLATE_SETTINGS_PATH,
      element: <ScriptTemplateSettings />,
      name: "scriptTemplateSettings",
    },
    {
      path: SCRIPT_TEMPLATE_SETTINGS_FORM_PATH,
      element: <ScriptTemplateSettingsForm />,
      name: "scriptTemplateSettingsForm",
    },
    {
      path: SCRIPT_QUIZ_LISTING,
      element: <QuizListing />,
      name: "quiz-library",
    },
    {
      path: SCRIPT_QUIZ_PATH,
      element: <TakeQuiz />,
      name: "Quiz",
    },
    {
      path: SCRIPT_QUIZ_RESULT_PATH,
      element: <QuizResult />,
      name: "QuizResult",
    },
    {
      path: SCRIPT_QUESTIONS_LISTING,
      element: <QuestionsListing />,
      name: "questions",
    },
    {
      path: SCRIPT_ADD_QUESTIONS,
      element: <AddEditQuestionPage />,
      name: "script-add-question",
    },
    {
      path: SCRIPT_EDIT_QUESTIONS,
      element: <AddEditQuestionPage />,
      name: "script-edit-question",
    },
    {
      path: SCRIPT_VIEW_QUESTIONS,
      element: <ViewScriptQuestion />,
      name: "script-view-question",
    },
    {
      path: CREATE_TEST_FORM,
      element: <CreateTestForm />,
      name: "create-test",
    },
  ];
};

export default getAppRoutes;
