import {
  Button,
  Card,
  DataTable,
  Group,
  Modal,
  notification,
  Tag,
  Title,
  Tooltip,
} from "components/common";
import { ActionButtons, Filters, TagsWithPlus } from "components/custom";
import CreateQuiz from "components/custom/quiz/createQuiz";
import { SCRIPT_QUIZ_PATH, SCRIPT_QUIZ_RESULT_PATH } from "constants/app-paths";
import { canUserModify } from "features/presentation/utils";
import {
  useGetAllQuizesQuery,
  useGetPageTopicsQuery,
  useGetSearchListingScriptTopicsQuery,
  useStartQuizMutation,
} from "features/script/api";
import { useStartTestMutation } from "features/test/apis";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { MdOutlineRestartAlt } from "react-icons/md";
import { VscDebugStart } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import theme from "utils/themeVars";

const quizListingColumns = [
  {
    title: "No.",
    dataIndex: "key",
    className: "tableStartSpace",
    width: 70,
  },
  {
    title: "Script",
    dataIndex: "scriptName",
    width: 300,
  },
  {
    title: "Explanation",
    dataIndex: "explanations",
    width: 300,
  },
  {
    title: "No. of Questions",
    dataIndex: "noOfQuestions",
  },
  {
    title: "Status",
    dataIndex: "quizStatus",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 150,
  },
];

const multipleModes = {
  scriptIds: true,
  topics: true,
  userIids: true,
};

function processObject(inputObj) {
  // Filter out keys with empty or undefined values
  const filteredObj = Object.fromEntries(
    Object.entries(inputObj).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  // Convert comma-separated values to arrays
  const processedObj = Object.fromEntries(
    Object.entries(filteredObj).map(([key, value]) => {
      return [key, multipleModes?.[key] ? value.split(",") : value];
    })
  );

  return processedObj;
}

const QuizListing = () => {
  const [visible, setVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);
  const user = useSelector((state) => state.app.user?.info);
  const navigate = useNavigate();
  const { data: allScripts, isLoading: scriptsLoading } =
    useGetPageTopicsQuery("");
  const { data: allTopics } = useGetSearchListingScriptTopicsQuery();

  const { data, isLoading, error, refetch, isFetching } = useGetAllQuizesQuery({
    ...processObject({ ...queryObj, userIids: user?.user_code }),
  });

  const [startTest, { isLoading: startingTest }] = useStartTestMutation();

  const onStartTest = async (e) => {
    const res = await startTest({ quizId: e?._id });
    if (res?.data?.error) {
      notification.error({ message: res?.data?.error });
    } else {
      navigate(SCRIPT_QUIZ_PATH.replace(":id", e?._id));
    }
  };

  const getActionsList = (e) => {
    return [
      {
        key: "view_result",
        icon: <IoMdEye />,
        color: canUserModify(user, e?.editor_id)
          ? theme["blue200"]
          : theme["gray500"],
        content: "View Result",
        title: "View Result",
        className: "pointer",
        onClick: () => {
          navigate(SCRIPT_QUIZ_RESULT_PATH.replace(":id", e?._id));
        },
      },
      {
        key: "start_test",
        icon: <VscDebugStart />,
        color: canUserModify(user, e?.editor_id)
          ? theme["blue200"]
          : theme["gray500"],
        content: "Start",
        title: "Start",
        className: "pointer",
        disabled: e?.quizStatus != "not started" || !e?.isTest,
        onClick: () => {
          onStartTest(e);
          // navigate(SCRIPT_QUIZ_RESULT_PATH.replace(":id", e?._id));
        },
      },
      {
        key: "reset",
        icon: <MdOutlineRestartAlt />,
        color: canUserModify(user, e?.editor_id)
          ? theme["blue200"]
          : theme["gray500"],
        content: "Reset",
        title: "Reset",
        className: "pointer",
        disabled: e?.quizStatus != "completed",
        onClick: () => {
          // navigate(SCRIPT_QUIZ_RESULT_PATH.replace(":id", e?._id));
        },
      },
    ];
  };

  const findScript = (scripts) => {
    if (allScripts?.length && scripts?.length) {
      return allScripts
        .filter(
          (e) =>
            scripts.includes(e.editor_template_id) || scripts?.includes(e?._id)
        )
        ?.map((e) => e?.page_name);
    }
    return ["-"];
  };

  const tableData = useCallback(() => {
    return data?.length
      ? data.map((e, index) => ({
          key: index + 1,
          explanations: (
            <TagsWithPlus
              data={e?.explanations?.map((e) => e?.title)}
              plusAfter={3}
            />
          ),
          noOfQuestions: e?.questionsList?.length,
          quizStatus: (
            <Tag color="#60A7DB" style={{ borderRadius: "40px" }}>
              {capitalizeFirstLetter(
                e?.quizStatus == "not started"
                  ? "Pending"
                  : e?.quizStatus == "started"
                  ? "In Progress"
                  : e?.quizStatus
              )}
            </Tag>
          ),
          scriptName: (
            <TagsWithPlus data={findScript([e?.scriptId])} plusAfter={3} />
          ),
          actions: <ActionButtons list={getActionsList(e)} />,
        }))
      : [];
  }, [allScripts, data]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  // Get the element by its class name
  const element = document.querySelector(".ant-table-header");

  // Check if the element exists before modifying its style
  if (element) {
    // Change its CSS properties
    element.style.overflow = "unset"; // Example of changing the overflow property
  }

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>Quizzes</Title>
        {/* <Button type="primary" onClick={() => setVisible(true)}>
          Start a Quiz
        </Button> */}
      </div>
    );
  };

  const filterList = useMemo(
    () => [
      {
        label: "none",
        type: "input",
        key: "quizId",
        placeholder: "Search by Quiz ID",
      },
      {
        label: "none",
        list: allScripts?.length
          ? allScripts.map((e) => ({
              label: e?.page_name,
              value: e?.editor_template_id,
            }))
          : [],
        type: "select",
        key: "scriptIds",
        placeholder: "Search By Script",
        mode: "multiple",
      },
      {
        label: "none",
        list: allTopics?.data?.length
          ? allTopics?.data?.map((e) => ({
              ...e,
              label: e?.PageTopics,
              value: e?._id,
            }))
          : [],
        type: "select",
        key: "topics",
        placeholder: "Search By Topic",
        mode: "multiple",
      },
      {
        label: "none",
        list: [
          { label: "Completed", value: "completed" },
          { label: "Started", value: "Started" },
        ],
        type: "select",
        key: "status",
        placeholder: "Search By Status",
      },
      {
        label: "Search By Date Attempted",
        type: "date",
        key: "createdAt",
        placeholder: "Search Dy Date Attempted",
      },
    ],
    [allScripts, allTopics]
  );

  const renderQuizListingsWeb = () => {
    return (
      <>
        <CreateQuiz visible={visible} setVisible={setVisible} />

        <Group gap="16px" className={"listings-content-adjustments"}>
          <Card>
            {renderPageHeader()}
            <Filters list={filterList || []} />
          </Card>

          <DataTable
            pagination={{
              totalCount: data?.pagination?.totalCount,
              pageOptions: [10, 25, 50],
            }}
            paginationOnBottom={true}
            onChangePage={(queryObj) => setSearchParams(queryObj)}
            columns={quizListingColumns}
            data={tableData()}
            loading={isLoading || scriptsLoading}
            error={error}
            onErrorRetry={refetch}
            skeletonLoading={isFetching}
            stickyOffset={0}
            noUrlPush={false}
          />
        </Group>
      </>
    );
  };

  return <>{renderQuizListingsWeb()}</>;
};
export default QuizListing;
