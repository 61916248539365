import { useEffect, useState } from "react";
import styles from "../index.module.less";
import ScriptLeftPanel from "../addScriptForm/scriptLeftPanel";
import ScriptDetailRightPanel from "./scriptDetailRightPanel";
import { Spinner } from "components/common";
import {
  useGetScriptDetailQuery,
  useGetTemplateHistoryQuery,
} from "features/script/api";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setScriptsDetailData } from "store/scriptDetailSlice";

const ScriptDetails = () => {
  const params = useParams();
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(null);

  const details = useSelector((state) => state?.scriptDetail?.data);
  const user = useSelector((state) => state?.app?.user?.info);

  const {
    data: templateHistory,
    isLoading: historyLoading,
    error: historyError,
    refetch,
  } = useGetTemplateHistoryQuery(
    {
      page_name: params?.id,
    },
    { skip: !params?.id }
  );

  const {
    data: scriptDetails,
    isLoading: scriptDetailsLoading,
    error: detailError,
  } = useGetScriptDetailQuery(
    {
      editor_template_id: queryObj?.editorId,
      version: queryObj?.version,
    },
    { skip: !queryObj }
  );

  // get selected version from scriptDetailRightPanel and update templdate
  const updateSelectedTemplate = (selectedTemplate) => {
    setTemplate(selectedTemplate);
  };

  useEffect(() => {
    if (scriptDetails && Object.keys(scriptDetails)?.length) {
      let temp = JSON.parse(
        JSON.stringify(scriptDetails?.assessment_and_plans)
      );
      let final = [];
      const index = temp.findIndex(
        (obj) => !!obj?.pinnedBy?.find((e) => e === user?._id)
      );
      if (index === -1 || index === 0) {
        final = temp;
      } else {
        final = [
          temp[index],
          ...temp.slice(0, index),
          ...temp.slice(index + 1),
        ];
      }
      dispatch(
        setScriptsDetailData({
          ...scriptDetails,
          scriptId: scriptDetails?.editor_template_id,
          editor_template_id: scriptDetails?.editor_template_id,
          assessment_and_plans: final,
        })
      );
    }
  }, [scriptDetails]);

  function createLeftSection() {
    const arr = [];
    arr.push({ id: "Presentation", title: "Presentation" });
    scriptDetails?.section_2?.forEach((element) => {
      arr.push({ id: element.title, title: element.title });
    });
    arr.push({ title: "Assessment and Plan" });
    scriptDetails?.schemas?.length && arr.push({ title: "Schemas" });
    arr.push({ title: "Notes" });
    scriptDetails?.podCast?.length && arr.push({ title: "PodCasts" });

    return arr;
  }

  return historyLoading || scriptDetailsLoading ? (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  ) : historyError || detailError ? (
    "Error"
  ) : (
    <div className={styles.pageContainer}>
      {!isMobile && <ScriptLeftPanel explanations={createLeftSection()} />}
      <ScriptDetailRightPanel
        data={details}
        templateVersions={templateHistory}
        updateSelectedTemplate={updateSelectedTemplate}
        isMobile={isMobile}
        queryObj={queryObj}
      />
    </div>
  );
};

export default ScriptDetails;
