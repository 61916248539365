import { Button, ImageUpload, Title } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import {
  useGetPageTopicsQuery,
  useGetScriptsExplanationsQuery,
  useGetSearchListingScriptTopicsQuery,
  useLazyGetScriptsExplanationsQuery,
} from "features/script/api";
import { useEffect, useState } from "react";
import theme from "utils/themeVars";
import QuestionAnswerOptions from "./questionAnswerOption";
import QuestionFormOptions from "./questionFormOptions";
import { useParams, useSearchParams } from "react-router-dom";
import DraggerView from "components/common/draggerView/draggerView";
import ImageSlider from "components/common/slider/slider";

const QuestionForm = ({
  data,
  onSubmit,
  inScriptForm,
  editForm,
  submitLoading,
  formData,
  setFormData,
}) => {
  const { data: allScripts, isLoading } = useGetPageTopicsQuery("");
  const { data: allTopics } = useGetSearchListingScriptTopicsQuery();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);

  const [getScriptsExplanations, { data: allExplanations }] =
    useLazyGetScriptsExplanationsQuery();

  const getExplanations = async (scripts) => {
    await getScriptsExplanations({ scriptIds: scripts });
  };

  useEffect(() => {
    if (formData?.scripts?.length) {
      getExplanations(formData?.scripts);
    }
  }, [formData?.scripts]);

  useEffect(() => {
    if (data) {
      setFormData(data);
    } else if (queryObj?.editorId) {
      setFormData({
        scripts: [queryObj?.editorId],
      });
    }
  }, [data]);

  return (
    <div
      style={{
        border: `1px solid ${theme["blue100"]}`,
        borderRadius: "10px",
        margin: "2%",
        padding: "2%",
      }}
    >
      <QuestionFormOptions
        scripts={allScripts}
        explanations={allExplanations}
        topics={allTopics?.data}
        formData={formData}
        setFormData={setFormData}
        editForm={editForm}
        inScriptForm={inScriptForm}
      />
      <TextEditor
        key={"Question"}
        placeholder={"Add question here"}
        value={formData?.description}
        onChange={(value) => {
          setFormData((prev) => ({ ...prev, description: value }));
        }}
        editorHeight={200}
      />
      <div style={{ marginTop: "2%" }}>
        <Title level={3}>Attachment</Title>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {!formData?.images?.length && (
            <ImageUpload
              onUpload={(file, uploadedFile) => {
                setFormData((prev) => ({ ...prev, images: [file.location] }));
                // form.setFieldValue("url", file?.location);
              }}
              setLoading={(value) => {
                // setLoading(value);
              }}
            >
              <DraggerView />
            </ImageUpload>
          )}
          {formData?.images?.[0] && (
            <ImageSlider
              data={[{ type: "image", url: formData?.images?.[0] }]}
              itemToShow={1}
              itemHeight={150}
              itemWidth={150}
              showActions={true}
              actions={["preview", "delete"]}
              onDelete={() => {
                setFormData((prev) => ({ ...prev, images: [] }));
              }}
            />
          )}
        </div>
      </div>

      {formData?.questionId && (
        <Title style={{ marginTop: "1%" }} level={5}>
          Question ID: {data?.questionId}
        </Title>
      )}

      <QuestionAnswerOptions
        options={formData?.options}
        setOptions={(options) => {
          setFormData((prev) => ({ ...prev, options }));
        }}
        correctAnswer={formData?.correctAnswer}
        setCorrectAnswer={(correctAnswer) => {
          console.log("===corr", correctAnswer);
          setFormData((prev) => ({ ...prev, correctAnswer }));
        }}
      />

      <div style={{ marginTop: "2%" }}>
        <Title level={3}>Explanation</Title>
        <TextEditor
          key={"additonalInfo"}
          placeholder={"Add an explanation for the right answer"}
          value={formData?.additionalInfo}
          onChange={(value) => {
            setFormData((prev) => ({ ...prev, additionalInfo: value }));
          }}
          editorHeight={200}
        />
      </div>

      {!inScriptForm && (
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "2%" }}
        >
          <Button
            text={editForm ? "Update" : "Create"}
            type="primary"
            onClick={() => onSubmit(formData)}
            loading={submitLoading}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionForm;
