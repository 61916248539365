import { Button, Icon, Title } from "components/common";
import theme from "utils/themeVars";

const QuizQuestionActions = ({
  onSkipQuestion,
  onQuestionSubmit,
  onNext,
  disableSubmit,
  disableNext,
  questionId,
  disableSkip,
  showNext,
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Title level={5}>Question ID: {questionId}</Title>
      <div style={{ display: "flex", gap: "5px" }}>
        {!disableSkip && (
          <Button
            type="bordered"
            style={{
              borderColor: theme["blue100"],
              color: theme["blue200"],
              background: theme["light-gray"],
            }}
            onClick={onSkipQuestion}
            disabled={disableSkip}
          >
            Skip
          </Button>
        )}
        <Button
          type="primary"
          onClick={onQuestionSubmit}
          text="Submit"
          disabled={disableSubmit}
        />
        {showNext && (
          <Button
            type="bordered"
            style={{
              borderColor: theme["blue100"],
              color: theme["blue200"],
              background: theme["light-gray"],
            }}
            onClick={onNext}
            disabled={disableNext}
          >
            <div style={{ display: "flex", gap: "5px" }}>
              Next<Icon size="1.1rem" icon="FaArrowRight"></Icon>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuizQuestionActions;
