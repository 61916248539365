import { Image, Menu, Typography } from "antd";
import {
  Card,
  Dropdown,
  Icon,
  Modal,
  Switch,
  TextInput,
  Title,
  Tooltip,
  notification,
} from "components/common";
import Button from "components/common/button/button";
import CollapseCard from "components/common/collapseCard/collapseCard";
import ImageSlider from "components/common/slider/slider";
import {
  ADD_ASSESSMENT,
  COPY_ASSESSMENT,
  DELETE_ICON,
  EDIT_ASSESSMENT,
  PIN,
} from "components/svg";
import { renderQuillHtml } from "configs/utils";
import {
  EDIT_SCRIPT_PATH,
  SCRIPT,
  SCRIPT_DETAIL_PATH,
} from "constants/app-paths";
import CircularArrow from "features/presentation/components/arrowSlider";
import { canUserModify } from "features/presentation/utils";
import {
  useAddFeedbackMutation,
  useChangeHiddenStatusMutation,
  useGetAllQuestionsOfScriptQuery,
  useGetScriptHiddenStatusMutation,
  useRestoreTemplateMutation,
  useUpdateScriptMutation,
} from "features/script/api";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setScriptsDetailData } from "store/scriptDetailSlice";
import { convertQueryObjToString } from "utils";
import theme from "utils/themeVars";
import AddFileModal from "../addScriptForm/addFileModal";
import AddImageVideoModal from "../addScriptForm/addImageVideoModal";
import AssessmentAndPlanPanel from "../addScriptForm/assessmentAndPlanPanel";
import SchemaModal from "../addScriptForm/schemaModal";
import ScriptNotesSection from "../addScriptForm/scriptNotesSection.js";
import styles from "../index.module.less";
import TextEditor from "components/common/textEditor/textEditor";
import ScriptQuestionSection from "../addScriptForm/questions/scriptQuestionSection";
import { setQuestionsData } from "store/scriptSlice";
import { getScriptPayload } from "../addScriptForm/getScriptPayload";
const { Paragraph } = Typography;

const ScriptDetailRightPanel = (props) => {
  const {
    data: details = [],
    templateVersions = [],
    isMobile = false,
    previewMode = false,
    showBack = false,
  } = props;
  const [restoreScript, restoreScriptResponse] = useRestoreTemplateMutation();
  const [updateData, { isLoading: updateLoading }] = useUpdateScriptMutation();
  const [checkPageHiddenStatus, { isLoading: isLoadingCheckHiddenPages }] =
    useGetScriptHiddenStatusMutation();
  const [changeHiddenStatus, { isLoading: resetLoading }] =
    useChangeHiddenStatusMutation();
  const [addFeedback, { isLoading: feedbackLoading }] =
    useAddFeedbackMutation();

  const { data: scriptQuestions } = useGetAllQuestionsOfScriptQuery(
    { scriptId: details?.editor_template_id },
    { refetchOnMountOrArgChange: true, skip: !details?.editor_template_id }
  );

  useEffect(() => {
    dispatch(
      setScriptsDetailData({
        questions: scriptQuestions?.length ? scriptQuestions : [],
      })
    );
  }, [scriptQuestions]);

  const handleAddFeedback = async () => {
    const res = await addFeedback({});
    if (res?.error) {
      notification.error({ message: res?.error?.message });
    } else {
      notification.success("Feedback Added Successfully!");
    }
  };

  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    title: "",
    description: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedAssessment, setSelectedAssessment] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeKeys, setActiveKeys] = useState(["0"]);
  const [subSlide, setSubSlide] = useState(0);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [isPageHidden, setIsPageHidden] = useState(false);
  const user = useSelector((state) => state.app.user?.info);
  let podCast = useSelector((state) => state?.scripts?.data?.podCast);
  podCast = podCast?.length ? podCast : details?.podCast;
  const convertDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const handleChange = (key, value) => {
    dispatch(setScriptsDetailData({ [key]: value }));
  };

  const handleAddEditAssessment = async (updatedData, message) => {
    const tempDetails = JSON.parse(JSON.stringify(details));
    const tempUpdatedData = JSON.parse(JSON.stringify(updatedData));
    const response = await updateData({
      ...tempDetails,
      assessment_and_plans: tempDetails?.assessment_and_plans?.length
        ? tempDetails?.assessment_and_plans.map((obj) => {
            if (obj.hasOwnProperty("editor")) delete obj["editor"];
            return obj;
          })
        : [],
      ...(updatedData && {
        assessment_and_plans: tempUpdatedData?.length
          ? tempUpdatedData.map((obj) => {
              if (obj.hasOwnProperty("editor")) delete obj["editor"];
              return obj;
            })
          : [],
      }),
      isUpdatingAssessments: true,
    });
    if (response) {
      if (response?.error) {
        notification.error({ message: response?.error?.message });
      } else {
        message && notification.success({ message: message });
        updatedData && setSelectedAssessment(0);
      }
    }
  };

  const updatedTemplateVersions = useCallback(() => {
    if (!templateVersions || templateVersions.length === 0) {
      return (
        <Menu>
          <Menu.Item disabled>No version found</Menu.Item>
        </Menu>
      );
    }

    return (
      <Menu>
        {templateVersions.map((it, index) => (
          <Menu.ItemGroup key={index} title={it.label}>
            {it?.options?.map((version, key) => (
              <Menu.Item key={index + key + 1}>
                <div onClick={() => updateVersion(version?.version)}>
                  <div
                    style={{
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    key={index + key + "child"}
                  >
                    {version.updatedByData
                      ? version.updatedByData?.username
                      : "-"}
                  </div>
                  <div style={{ color: "#A7A7A7", fontSize: "12px" }}>
                    <span style={{ color: "#707070", fontSize: "12px" }}>
                      {convertDate(version.updated_at)}
                    </span>
                  </div>{" "}
                </div>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        ))}
      </Menu>
    );
  }, [templateVersions]);
  useEffect(() => {
    if (details?.editor_template_id) {
      checkPageHiddenStatus({
        editor_template_id: details?.editor_template_id,
      }).then((res) => {
        setIsPageHidden(res?.data?.data?.is_hidden);
      });
    }
    if (details?.section_2?.length) {
      setActiveKeys(details?.section_2?.map((e, index) => index?.toString()));
    }
  }, [details]);
  const handleTitleClick = (item, index) => {
    setSelectedTitle(item?.title);
    // You can use the title (or any unique identifier) to scroll the selected item into view
    const element = document.getElementById(
      `${index}-explanation-collapse-card`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  /**
   * This function will pick the section_2 from data object and will map
   * the data as per required format and store that in section_2 array.
   * @returns returns an array in the required format of collapsed card
   */
  function mapperForSection_2() {
    const section_2 = [];

    details?.section_2?.forEach((element, index) => {
      const allMedia = [
        ...element?.media,
        ...(element?.LicensedImage?.length
          ? element?.LicensedImage?.map((e) => ({ ...e, type: "licenseImage" }))
          : []),
        ...(element?.YTIframe?.length
          ? element?.YTIframe?.map((e) => ({ ...e, type: "licenseVideo" }))
          : []),
        ...(element?.LicensedPodcast?.length
          ? element?.LicensedPodcast?.map((e) => ({
              ...e,
              type: "licensePodcast",
            }))
          : []),
      ];

      const obj = {
        ...element,
        key: `${index + 1}-explanation-collapse-card`,
        header: (
          <Title level={3} style={{ pointerEvents: "all", width: "90%" }}>
            {element.title}
          </Title>
        ),
        panel: (
          <>
            {renderQuillHtml(element.explanation, details?.editor_template_id)}

            {allMedia?.length ? (
              <div style={{ marginTop: "1%" }}>
                <ImageSlider
                  data={allMedia?.length ? allMedia : []}
                  itemToShow={allMedia?.length < 5 ? allMedia?.length : 5}
                  itemHeight={125}
                  showActions={true}
                  viewOnly={true}
                  onPreview={(item, index) => {
                    setSelectedItem(item);
                  }}
                />
              </div>
            ) : null}
          </>
        ),
      };
      section_2.push(obj);
    });

    return section_2;
  }

  const canEditAssessment = () => {
    return user?.user_role == "Admin" || details?.editor_id == user?.user_code;
  };

  const handleChangePin = () => {
    let tempAssessments = JSON.parse(
      JSON.stringify(details?.assessment_and_plans)
    );
    tempAssessments = tempAssessments.map((ass, i) => {
      if (i != selectedAssessment) {
        return {
          ...ass,
          ...(ass?.pinnedBy?.length
            ? {
                pinnedBy: ass?.pinnedBy?.filter((e) => e == user?.id),
              }
            : {}),
        };
      } else {
        return ass;
      }
    });
    //Updating its pinned for the selected assessment
    tempAssessments = tempAssessments.map((ass, i) => {
      return {
        ...ass,
        ...(i == selectedAssessment
          ? {
              pinnedBy: !!ass?.pinnedBy?.find((e) => e == user?._id)
                ? ass?.pinnedBy?.filter((e) => e != user?._id)
                : [...ass?.pinnedBy, user?._id],
            }
          : {}),
      };
    });
    handleAddEditAssessment(
      tempAssessments,
      !!tempAssessments?.[selectedAssessment]?.pinnedBy?.find(
        (e) => e == user?._id
      )
        ? "Pinned Successfully!"
        : "Pin Removed Successfully!"
    );
  };

  const handleAddAssessmentAndPlan = () => {
    let tempAssessments = JSON.parse(
      JSON.stringify(details?.assessment_and_plans)
    );
    tempAssessments?.push({
      key: tempAssessments?.length?.toString(),
      description: "",
      createdBy: user?._id,
      userName: user?.firstname + " " + user?.lastname,
      pinnedBy: [],
      editor: true,
    });
    handleChange("assessment_and_plans", tempAssessments);
    setSelectedAssessment(details?.assessment_and_plans?.length);
  };

  const handleEdit = () => {
    let tempAssessments = JSON.parse(
      JSON.stringify(details?.assessment_and_plans)
    );
    tempAssessments[selectedAssessment] = {
      ...tempAssessments?.[selectedAssessment],
      editor: !tempAssessments?.[selectedAssessment]?.editor,
    };
    handleChange("assessment_and_plans", tempAssessments);
  };

  const handleDeleteAP = () => {
    let tempAssessments = JSON.parse(
      JSON.stringify(details?.assessment_and_plans)
    );
    handleAddEditAssessment(
      tempAssessments.filter((e, i) => i != selectedAssessment),
      "Deleted Successfully!"
    );
  };

  function removeTagsAndStyling(inputString) {
    if (!inputString) return null;
    const tempDiv = document.createElement("div");
    const formattedHTML = inputString.replace(/<\/p>/g, "\n");
    tempDiv.innerHTML = formattedHTML;
    const plainText = tempDiv.textContent || tempDiv.innerText;
    const cleanedText = plainText.trim();
    return cleanedText;
  }

  const handleCopyToClipboard = () => {
    let text = removeTagsAndStyling(
      details?.assessment_and_plans?.[selectedAssessment]?.description
    );
    if (!text) return null;
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    notification.success({ message: "Copied Successfully!" });
  };

  const AssessmentAndPlanHeader = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={3}>
            Assessment And Plan ({details?.assessment_and_plans?.length})
          </Title>
          {!previewMode && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                paddingRight: "5%",
              }}
            >
              <Tooltip title="Pin/UnPin">
                <div onClick={handleChangePin} className={styles.circle}>
                  <PIN
                    clr={
                      details?.assessment_and_plans?.[
                        selectedAssessment
                      ]?.pinnedBy?.find((e) => e == user?._id)
                        ? "#1565DC"
                        : "#808080"
                    }
                  />
                </div>
              </Tooltip>
              <Tooltip title="Add">
                <div
                  onClick={handleAddAssessmentAndPlan}
                  className={styles.circle}
                >
                  <ADD_ASSESSMENT />
                </div>
              </Tooltip>
              {(details?.assessment_and_plans?.[selectedAssessment]
                ?.createdBy == user?._id ||
                user?.user_role == "ADMIN") && (
                <Tooltip title="Edit">
                  <div onClick={handleEdit} className={styles.circle}>
                    <EDIT_ASSESSMENT />
                  </div>
                </Tooltip>
              )}
              <Tooltip title="Copy Text">
                <div onClick={handleCopyToClipboard} className={styles.circle}>
                  <COPY_ASSESSMENT />
                </div>
              </Tooltip>
              {(details?.assessment_and_plans?.[selectedAssessment]
                ?.createdBy == user?._id ||
                user?.user_role == "ADMIN") && (
                <Tooltip title="Delete">
                  <div onClick={handleDeleteAP} className={styles.circle}>
                    <DELETE_ICON />
                  </div>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const getAssessmentAndPlans = () => {
    return [
      {
        key: `${details?.section_2?.length + 1}-explanation-collapse-card`, //Do not remove this id as this represents assesent and plan section to scroll from left card
        header: AssessmentAndPlanHeader(),
        panel: (
          <AssessmentAndPlanPanel
            index={selectedAssessment}
            data={details?.assessment_and_plans?.[selectedAssessment]}
            assessments={details?.assessment_and_plans}
            setSelectedAssessment={setSelectedAssessment}
            handleChange={handleChange}
            keyValue={"assessment_and_plans"}
            detailPage={true}
            previewMode={previewMode}
            onSubmitAssessment={handleAddEditAssessment}
          />
        ),
      },
    ];
  };

  const [selectedVersion, setSelectedVersion] = useState(null);

  const transformedVersionHistory = useCallback(() => {
    if (templateVersions?.length) {
      return [templateVersions].reduce((acc, version) => {
        if (version && version.options) {
          acc.push(...version.options);
        }
        return acc;
      }, []);
    }
    return [];
  }, [templateVersions]);

  const isLatestVersionSelected = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const version = parseInt(urlParams.get("version"));
    return transformedVersionHistory()?.every((obj) => version > obj.version);
  };

  const updateVersion = (newVersion) => {
    const url = new URL(window.location.href);
    url.searchParams.set("version", newVersion);
    window.open(url);
  };

  // Check if data has both description and images or only one of them
  const hasDescription = details?.section_1?.length ? true : false;
  const hasImages = details?.media?.length ? true : false;

  const renderBasicDetails = () => {
    return (
      <Card bodyStyle={{ padding: "2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            {showBack && (
              <Button
                type="text"
                shape="circle"
                style={{ "--btn-color": theme["accent-color"] }}
                iconColor={theme["accent-color"]}
                icon="HiArrowLeft"
                onClick={() => navigate(-1)}
              />
            )}
            <Title level={2}>{details?.page_name}</Title>
          </div>
          <div>
            <div style={{ display: "flex", gap: "15px" }}>
              {!isLatestVersionSelected() && !previewMode && (
                <Button
                  style={{
                    color: "white",
                    background: "#4b93ff",
                    fontWeight: 700,
                    fontSize: "1rem",
                    height: "48px",
                  }}
                  onClick={() => {
                    if (details?.editor_template_id) {
                      restoreScript({
                        editor_template_id: details?.editor_template_id,
                        updated_at: details?.updated_at,
                      })
                        .unwrap()
                        .then(() => {
                          notification.success({
                            message: "Script has been Restored!",
                          });
                          navigate(SCRIPT);
                        })
                        .catch((e) => {
                          notification.error({
                            message: e?.message,
                          });
                        });
                    }
                  }}
                >
                  Restore
                </Button>
              )}
              {!previewMode && user?.user_role == "ADMIN" && (
                <Dropdown overlay={updatedTemplateVersions()} suffixIcon="">
                  <Button
                    type="bordered"
                    size="large"
                    color={theme["primary-color"]}
                    style={{
                      borderColor: theme["blue100"],
                      color: theme["blue200"],
                      background: theme["light-gray"],
                      fontWeight: 700,
                      fontSize: "1rem",
                      height: "48px",
                      width: isMobile ? "2vw" : "unset",
                    }}
                  >
                    {isMobile ? "History" : "Version History"}
                  </Button>
                </Dropdown>
              )}
              {!previewMode && (
                <Button
                  type="bordered"
                  size="large"
                  color={theme["primary-color"]}
                  style={{
                    borderColor: theme["blue100"],
                    color: theme["blue200"],
                    background: theme["light-gray"],
                    fontWeight: 700,
                    fontSize: "1rem",
                    height: "48px",
                    width: isMobile ? "2vw" : "unset",
                  }}
                  onClick={() => setFeedbackVisible(true)}
                >
                  {isMobile ? "Feedback" : "Add Feedback"}
                </Button>
              )}
              {!previewMode && canUserModify(user, details?.editor_id) && (
                <Button
                  type="bordered"
                  icon="FaRegEdit"
                  style={{
                    borderColor: theme["blue100"],
                    color: theme["blue200"],
                    background: theme["light-gray"],
                    fontWeight: 700,
                    fontSize: "1rem",
                    height: "48px",
                  }}
                  onClick={() => {
                    navigate(
                      `${EDIT_SCRIPT_PATH.replace(
                        ":name",
                        details?.page_name
                      )}?editorId=${details?.editor_template_id}&version=${
                        details?.version
                      }`
                    );
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  };

  const renderScriptDetailsCard = () => {
    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1% 2% 1% 2%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div>
              <Title level={4}>Specialties</Title>
              <div style={{ marginTop: "-0.5" }}>
                {details?.speciality?.join(", ")}
              </div>
            </div>
            <div style={{ display: "flex", gap: "2px" }}>
              <Title style={{ marginRight: "1vw" }} level={4}>
                Page ID:{" "}
              </Title>
              <div>{details?.page_id}</div>
            </div>
            {/* <div style={{ display: "flex", gap: "2px" }}>
              <Title style={{ marginRight: "1vw" }} level={4}>
                Modified By:{" "}
              </Title>
              <div>{details?.page_id}</div>
            </div> */}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {details?.topics?.length ? (
              <div>
                <Title level={4}>Topics</Title>
                <div style={{ marginTop: "-10px" }}>
                  {details?.topics?.join(",")}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {!previewMode && (
              <>
                <div style={{ display: "flex" }}>
                  <Title
                    style={{ marginRight: "1vw" }}
                    level={4}
                  >{`Modified:`}</Title>
                  {details?.updated_at && convertDate(details?.updated_at)}
                </div>
                <div style={{ display: "flex" }}>
                  <Title style={{ marginRight: "1vw" }} level={4}>
                    Hide Page:{" "}
                  </Title>
                  <Switch
                    onChange={() => {
                      changeHiddenStatus({
                        editor_template_id: details.editor_template_id,
                        is_hidden: !isPageHidden,
                      })
                        .then(() => {
                          notification.success({
                            message: "Page successfully marked hidden.",
                          });
                          setIsPageHidden(!isPageHidden);
                        })
                        .catch((e) => {
                          notification.error({
                            message: e?.message,
                          });
                        });
                    }}
                    value={isPageHidden}
                  />
                </div>
              </>
            )}
          </div>
          <div></div>
        </div>
      </Card>
    );
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1%",
        }}
      >
        <Title level={2}>Explanations</Title>
        <Button
          text={
            activeKeys?.length == details?.section_2?.length
              ? "Contract All"
              : "Expand All"
          }
          type="bordered"
          style={{
            borderColor: theme["blue100"],
            color: theme["blue200"],
            background: theme["light-gray"],
          }}
          icon={
            activeKeys?.length == details?.section_2?.length
              ? "BsArrowsCollapse"
              : "BsArrowsExpand"
          }
          iconProps={{ size: "16px" }}
          size="small"
          onClick={() =>
            activeKeys?.length == details?.section_2?.length
              ? setActiveKeys([])
              : setActiveKeys(details?.section_2?.map((e, i) => i.toString()))
          }
        />
      </div>
    );
  };

  const renderPresentationSection = () => {
    return (
      <Card
        id={`${0}-explanation-collapse-card`}
        style={{ ...(!isMobile && { maxHeight: "1200px", overflow: "auto" }) }}
      >
        <Title
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1%",
          }}
          level={2}
        >
          Presentation
        </Title>
        <div style={{ padding: "2% 8% 2% 8%" }}>
          {hasDescription &&
            subSlide === 0 &&
            details?.section_1?.map((section, sectionIndex) => (
              <div id="Presentation" key="Presentation">
                <Title level={4}>{section.title}</Title>
                {renderQuillHtml(section.description)}
                {/* <Paragraph>{section.description}</Paragraph> */}
              </div>
            ))}
          {subSlide > 0 && details?.media?.[subSlide - 1] && (
            <Image
              src={details?.media?.[subSlide - 1]?.url}
              alt={`slide-${subSlide - 1}`}
              height={"600px"}
              width={`100%`}
            />
          )}
        </div>

        {hasDescription && hasImages && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5%",
            }}
          >
            <CircularArrow
              ref={null}
              totalSlides={details?.media?.length + 1}
              subSlide={subSlide}
              setSubSlide={setSubSlide} // Toggle between 0 and 1
              // applyCustomClass={true}
            />
          </div>
        )}
      </Card>
    );
  };

  const handleExpandCollapse = (key) => {
    const keyIndex = activeKeys.findIndex((e) => e == key);
    if (keyIndex == -1) {
      setActiveKeys((prev) => [...prev, key]);
    } else {
      setActiveKeys((prev) => prev.filter((e) => e != key));
    }
  };

  const renderExpalantionSection = () => {
    return (
      <Card>
        {renderHeader()}
        {mapperForSection_2()?.map((e, i) => (
          <div
            style={{
              border: `1px solid ${theme["blue100"]}`,
              borderRadius: "10px",
              margin: "2%",
              padding: "2% 1% 2% 1%",
            }}
            key={e?.key}
            id={e?.key}
          >
            <CollapseCard
              header={e?.header}
              panel={e?.panel}
              headerKey={i?.toString()}
              handleExpandCollapse={() => handleExpandCollapse(i?.toString())}
              activeKey={activeKeys?.find((e) => e == i?.toString())}
            />
          </div>
        ))}
      </Card>
    );
  };

  const renderAssessmentAndPlans = () => {
    return (
      <Card>
        {getAssessmentAndPlans()?.map((e, i) => (
          <div
            style={{
              border: `1px solid ${theme["blue100"]}`,
              borderRadius: "10px",
              margin: "2%",
              padding: "2% 1% 2% 1%",
            }}
            key={e?.key}
            id={e?.key}
          >
            <CollapseCard
              header={e?.header}
              panel={e?.panel}
              headerKey={i?.toString()}
              handleExpandCollapse={() => handleExpandCollapse(i?.toString())}
              activeKey={activeKeys?.find((e) => e == i?.toString())}
            />
          </div>
        ))}
      </Card>
    );
  };

  const renderPodCasts = () => {
    return (
      <>
        {podCast?.length ? (
          <>
            <Card
              id={`${
                details?.section_2?.length + (details?.schemas?.length ? 4 : 3)
              }-explanation-collapse-card`}
            >
              <div style={{ margin: "2%" }}>
                <Title>Podcast</Title>
                <div style={{ marginTop: "-2%" }}>
                  <ImageSlider
                    data={
                      podCast?.length
                        ? podCast?.map((e) => ({
                            ...e,
                            url: e?.ImageURL,
                          }))
                        : []
                    }
                    itemToShow={podCast?.length < 4 ? podCast?.length : 4}
                    itemHeight={150}
                    hideRemove={true}
                  />
                </div>
              </div>
            </Card>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderSchemas = () => {
    return details?.schemas?.length ? (
      <Card
        style={{ marginBottom: "10px" }}
        id={`${details?.section_2?.length + 2}-explanation-collapse-card`} //Do not remove this id as this represents schema section to scroll from left card
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
          className="pointer"
        >
          {details?.schemas.map((e, index) => (
            <div
              className={styles.schemaTile}
              onClick={() => {
                setSelectedSchema({
                  title: "",
                  type: "schema",
                  data: JSON.parse(e?.json),
                });
              }}
            >
              <Icon size={24} icon="MdOutlineSchema" />
              <div style={{ fontSize: "20px" }}>{`Schema - ${index + 1}`}</div>
              {/* <Icon size={10} icon="ImCross" /> */}
            </div>
          ))}
        </div>
      </Card>
    ) : null;
  };
  return (
    <>
      <AddFileModal
        isVisible={selectedItem?.type == "image"}
        setIsVisible={setSelectedItem}
        onConfirm={() => {}}
        data={selectedItem}
        destroyOnClose={true}
        renderFooter={false}
        detailPage={true}
        width={500}
      />
      <AddImageVideoModal
        isVisible={
          selectedItem?.type == "licenseImage" ||
          selectedAssessment?.type == "licenseVideo" ||
          selectedAssessment?.type == "licensePodcast"
        }
        setIsVisible={setSelectedItem}
        onConfirm={() => {}}
        data={selectedItem}
        type={selectedItem?.type}
        renderFooter={false}
        destroyOnClose={true}
        detailPage={true}
        width={500}
      />
      <SchemaModal
        onConfirm={() => {}}
        visible={selectedSchema}
        setIsVisible={setSelectedSchema}
        schemaData={selectedSchema}
        destroyOnClose
        onCancel={() => {
          setSelectedSchema(null);
        }}
        viewOnly={true}
      />
      <div className={isMobile ? styles.rightSectionMob : styles.rightSection}>
        {renderBasicDetails()}
        {renderPresentationSection()}

        {isMobile && (
          <Card>
            <p className={styles.explanation}>Explanations</p>
            <div className={styles.mobileLeftSectionParent}>
              {details?.section_2?.map((item, index) => (
                <div
                  key={item.title}
                  className={
                    item.title.toLowerCase() === selectedTitle?.toLowerCase()
                      ? styles.selectedItem
                      : styles.mobileLeftSection
                  }
                  onClick={() => handleTitleClick(item, index)}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </Card>
        )}

        {renderExpalantionSection()}
        {renderAssessmentAndPlans()}
        <ScriptQuestionSection
          detailPage={true}
          scriptData={{
            ...details,
            explanations: details?.section_2?.length
              ? details?.section_2.map((e, i) => ({
                  ...e,
                  key: i?.toString(),
                  title: e?.title,
                  description: e?.explanation,
                }))
              : [],
          }}
          scriptQuestionsApiData={scriptQuestions}
        />
        {!isMobile && renderSchemas()}
        {!previewMode && (
          <ScriptNotesSection
            scrollId={`${
              details?.section_2?.length + (details?.schemas?.length ? 3 : 2)
            }-explanation-collapse-card`}
            note={details?.user_note}
            onEdit={(val) =>
              handleChange("user_note", {
                ...details?.user_note,
                editable: !details?.user_note?.editable,
              })
            }
            onChange={(e) => {
              handleChange("user_note", { ...details?.user_note, note: e });
            }}
            setNotes={handleChange}
            editorTemplateId={details?.editor_template_id}
          />
        )}

        {renderPodCasts()}
        {renderScriptDetailsCard()}
      </div>
      <Modal
        visible={feedbackVisible}
        setVisible={setFeedbackVisible}
        title={"Add Feedback"}
        okText={"Submit"}
        onOk={handleAddFeedback}
        onCancel={() => setFeedbackVisible(false)}
      >
        <div>
          <TextInput
            placeholder={"Add Title"}
            value={feedbackData?.title}
            onChange={(e) =>
              setFeedbackData((prev) => ({ ...prev, title: e?.target?.value }))
            }
            style={{ marginBottom: "15px" }}
          />
          <TextEditor
            style={{ textAlign: "left" }}
            key={"DescriptionFeedback"}
            value={feedbackData?.description}
            onChange={(e) =>
              setFeedbackData((prev) => ({ ...prev, description: e }))
            }
            placeholder={"Add description"}
            toolbarVisible={true}
            editorHeight={200}
          />
        </div>
      </Modal>
    </>
  );
};

export default ScriptDetailRightPanel;
