import { DatePicker as AntdDatePicker } from "antd"
import moment from "moment"

const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}
const disabledFutureTime = date => {
  if (date?.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
    return {
      disabledHours: () => range(moment().hour() + 1, 24),
      disabledMinutes: () => range(moment().minute(), 60),
    }
  }
}

// const disabledPastTime = date => {
//   if (date?.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
//     return {
//       disabledHours: () => range(0, moment().hour()),
//       disabledMinutes: () => range(0, moment().minute()),
//     }
//   }
// }

function disableDateRanges(range = { startDate: false, endDate: false }) {
  const { startDate, endDate } = range
  return function disabledDate(current) {
    if (moment(endDate).isSame(current, "day")) {
      if (current.hours() >= moment(endDate).hours()) {
        return false
      }
    }
    let startCheck = true
    let endCheck = true

    if (startDate) {
      startCheck = current < moment(startDate)
    }
    if (endDate) {
      endCheck = current > moment(endDate)
    }
    return (startDate && startCheck) || (endDate && endCheck)
  }
}

const disabledDateFuture = date => {
  return date && date > moment()
}

const disabledDatePast = date => {
  return date && date < moment()
}

const DatePicker = props => {
  const { disableFuture, disablePast, range, disableFutureTime, disablePastTime, ...rest } = props
  return (
    <AntdDatePicker
      disabledDate={
        !!disableFuture
          ? disabledDateFuture
          : !!disablePast
          ? disabledDatePast
          : !!range
          ? disableDateRanges(range)
          : null
      }
      showTime={{ hideDisabledOptions: true }}
      disabledTime={!!disableFutureTime ? disabledFutureTime : null}
      {...rest}
    />
  )
}

export default DatePicker
