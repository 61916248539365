import { Col, Form, Row } from "antd";
import {
  Button,
  Card,
  Group,
  Legend,
  Modal,
  notification,
  SelectInput,
  Text,
  TextInput,
  Title,
} from "components/common";
import { SCRIPT_QUIZ_LISTING, SCRIPT_QUIZ_PATH } from "constants/app-paths";
import {
  useGetPlatformsQuery,
  useGetSearchListingScriptTopicsQuery,
  useGetSpecialitiesQuery,
} from "features/script/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { lowerCaseFirstLetter } from "utils";
import {
  useCreateTestMutation,
  useLazyGetQuestionsForTestQuery,
  useStartTestMutation,
} from "./apis";

const CreateTestForm = () => {
  const [form] = Form.useForm();
  const testQuestions = Form.useWatch("testQuestions", form);
  const testName = Form.useWatch("testName", form);
  const templateType = Form.useWatch("templateType", form);
  const speciality = Form.useWatch("specialty", form);
  const topics = Form.useWatch("topics", form);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { data: platforms } = useGetPlatformsQuery();
  const { data: specialties } = useGetSpecialitiesQuery();
  const { data: allTOpics } = useGetSearchListingScriptTopicsQuery("");
  const [getQuestionsForTest, { data: questions, isFetching }] =
    useLazyGetQuestionsForTestQuery();

  const [createTest, { data, isLoading: creating }] = useCreateTestMutation();
  const [startTest, { isLoading: startingTest }] = useStartTestMutation();

  const calculateNumberOfQuestion = async () => {
    let body = {
      topics: topics,
      speciality: speciality,
      templateType: templateType,
      testName: testName,
    };

    const questions = await getQuestionsForTest(body);
    if (questions?.data?.error) {
      notification.error(questions?.data?.error);
    } else {
      form.setFieldValue("testQuestions", questions?.data?.length);
    }
  };

  const handleSubmit = async (values) => {
    if (!testName) {
      notification.error({ description: "Please enter test name" });
    } else if (!templateType?.length) {
      notification.error({
        description: "Please select at least 1 Template Type",
      });
    } else {
      const res = await createTest({ questionIds: questions });
      if (res?.data?.error) {
        notification.error({ description: res?.data?.error });
      } else {
        setShowModal(true);
      }
    }
  };

  const onStartTest = async () => {
    const res = await startTest({ quizId: data?._id });
    if (res?.data?.error) {
      notification.error({ description: res?.data?.error });
    } else {
      navigate(SCRIPT_QUIZ_PATH.replace(":id", data?._id));
    }
  };

  return (
    <>
      <Modal
        visible={showModal}
        onOk={onStartTest}
        onCancel={() => {
          setShowModal(false);
          navigate(SCRIPT_QUIZ_LISTING);
        }}
        loading={startingTest}
        title={"Successfully Created"}
        okText={"Start Test"}
        cancelText={"Go to Tests"}
      >
        <Text>
          The test has been successfully created. Would you like to start it
          now?
        </Text>
      </Modal>
      <Card>
        <Row style={{ width: 810, marginInline: "auto" }}>
          <Col span={24}>
            <Form
              form={form}
              name="create-test-form"
              initialValues={{}}
              scrollToFirstError
              onFinish={handleSubmit}
              preserve={true}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              labelAlign={"left"}
              shouldUpdate
            >
              <Legend title={"Create Test"} />

              <Group gap="0" template="1fr" className="pi-20 mbe-32">
                <Form.Item
                  name="testName"
                  label="Test Name"
                  required
                  trigger="onChange"
                >
                  <TextInput
                    value={form.getFieldValue("testName")}
                    placeholder="Enter Test Name"
                    onChange={(e) => {
                      form.setFieldsValue({
                        testName: e.target.value,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="templateType"
                  label="Template Type"
                  trigger="onChange"
                >
                  <SelectInput
                    placeholder="Select Template Type"
                    options={platforms?.data.map((plat) => ({
                      label: plat,
                      value: lowerCaseFirstLetter(plat),
                    }))}
                    loading={false}
                    value={form.getFieldValue("templateType")}
                    onChange={(value, option) => {
                      form.setFieldsValue({
                        templateType: value,
                      });
                    }}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  name="specialty"
                  label="Specialty"
                  // required
                  trigger="onChange"
                >
                  <SelectInput
                    placeholder="Select Specialty"
                    options={specialties?.data.map((plat) => ({
                      label: plat.topic,
                      value: plat.topic,
                    }))}
                    loading={false}
                    value={form.getFieldValue("specialty")}
                    onChange={(value, option) => {
                      form.setFieldsValue({
                        specialty: value,
                      });
                    }}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item name="topics" label="Topics" trigger="onChange">
                  <SelectInput
                    placeholder="Add Topics"
                    options={
                      allTOpics?.data?.length
                        ? [...allTOpics?.data, form.getFieldValue("topics")]
                        : form.getFieldValue("topics")
                        ? form.getFieldValue("topics")
                        : []
                    }
                    getOptionValue={(e) => e?._id}
                    getOptionLabel={(e) => e?.PageTopics}
                    onChange={(val, option) => {
                      form.setFieldsValue({
                        topics: val,
                      });
                    }}
                    value={form.getFieldValue("topics")}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  name="testQuestions"
                  label="Calculate"
                  trigger="onChange"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      text={"Calculate"}
                      loading={isFetching}
                      disabled={
                        false
                        // !topics?.length ||
                        // !speciality?.length ||
                        // !templateType?.length
                      }
                      onClick={calculateNumberOfQuestion}
                    />
                    {testQuestions !== undefined && (
                      <Text style={testQuestions == 0 ? { color: "red" } : {}}>
                        You have {form.getFieldValue("testQuestions")} questions
                        in your test
                      </Text>
                    )}
                  </div>
                </Form.Item>
              </Group>

              <Form.Item
                shouldUpdate
                className="submit"
                wrapperCol={{ span: 24 }}
              >
                <Row style={{ gap: 8, justifyContent: "right" }}>
                  <Button
                    type="primary"
                    text={"Create Test"}
                    loading={creating}
                    onClick={form.submit}
                    disabled={!testQuestions}
                  />
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CreateTestForm;
